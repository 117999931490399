<script setup>
import Button from '@/Components/Buttons/Button.vue';
import Card from '@/Components/Card/Card.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import {Tab, TabGroup, TabList, TabPanel, TabPanels} from '@headlessui/vue';
import {computed, inject} from 'vue';
import EventTab from '@/Pages/Events/Partials/EventTab.vue';
import Quotation from '@/Pages/Events/Partials/Quotation.vue';

const dayjs = inject('dayjs');

const props = defineProps({
    event: Object,
    userOptions: Object,
    categoryInQuotation: Object,
    statusOptions: Object,
    contactOptions: Object,
    foodOptions: Object,
    drinkOptions: Object,
    templateOptions: Object,
    typeOptions: Object,
});

const isSingle = computed(() => props.event.children.length == 0);
</script>

<template>
    <AppLayout>
        <Card
            collapsable
            remove-padding
        >
            <template #title>
                {{ event.title }}
            </template>
            <EventTab
                v-if="typeof event.children == 'undefined' || event.children.length === 0"
                :event="event"
                :is-single="isSingle"
                :status-options="statusOptions"
                :user-options="userOptions"
            />
            <TabGroup>
                <TabList
                    v-if="event.children && event.children.length > 0"
                    class="-mb-px flex border-b border-gray-300 md:space-x-4"
                >
                    <Tab
                        v-for="event in [event, ...event.children]"
                        :key="event.id"
                        v-slot="{ selected }"
                        as="template"
                    >
                        <button
                            :class="{ '-mb-[2px] border-b-2 border-beige-500': selected }"
                            class="events-center relative flex w-full flex-col justify-center py-2 outline-0"
                        >
                            <div class="events-center mb-1 flex flex-1 px-8 text-center leading-tight md:min-h-10">
                                {{ event.title }}

                                <div
                                    :class="{
                                        'bg-pine-500 text-beige-50': event.accepted_attendances_count == event.capacity,
                                        'bg-beige-100 text-pine-600 ':
                                            event.accepted_attendances_count != event.capacity,
                                    }"
                                    class="absolute bottom-2 right-2 ml-3 hidden whitespace-nowrap rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block"
                                >
                                    {{ event.accepted_attendances_count }} / {{ event.capacity }}
                                </div>
                            </div>

                            <div
                                v-if="!event.all_day"
                                class="text-sm"
                            >
                                {{ dayjs(event.start).format('HH:mm') }} - {{ dayjs(event.end).format('HH:mm') }}
                            </div>
                        </button>
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel
                        v-for="event in [event, ...event.children]"
                        :key="event.id"
                        as="div"
                    >
                        <EventTab
                            :event="event"
                            :is-single="isSingle"
                            :status-options="statusOptions"
                            :user-options="userOptions"
                        />
                    </TabPanel>
                </TabPanels>
            </TabGroup>
        </Card>

        <Quotation
            v-if="event.type == 'event'"
            :category-in-quotation="categoryInQuotation"
            :contact-options="contactOptions"
            :drink-options="drinkOptions"
            :event="event"
            :food-options="foodOptions"
            :status-options="statusOptions"
            :template-options="templateOptions"
            :type-options="typeOptions"
        />
    </AppLayout>
</template>
