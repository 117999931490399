<script setup>
import FormButton from '@/Components/Buttons/FormButton.vue';
import Card from '@/Components/Card/Card.vue';
import {useUser} from '@/stores/userStore';
import Group from "@/Components/Input/Group.vue";
import Form from "@/Components/Input/Form.vue";

const user = useUser();

defineProps({
    form: Object,
});

</script>
<template>
    <Card
        collapsable
        collapsed
    >
        <template #title>
            {{ $t('Time registration settings') }}
        </template>

        <Form>
            <Group>
                <FormKit
                    v-model="form.overtime_warning_threshold"
                    :errors="form.errors.overtime_warning_threshold"
                    :help="$t('Send a warning when the overtime is above this threshold.')"
                    :label="$t('Overtime warning threshold')"
                    :step="1"
                    type="number"
                />
                <FormKit
                    v-model="form.overtime_warning_interval"
                    :errors="form.errors.overtime_warning_interval"
                    :help="$t('Only send a warning once every x days.')"
                    :label="$t('Overtime warning interval')"
                    :step="1"
                    type="number"
                />
            </Group>
        </Form>

        <template #buttons>
            <FormButton
                v-if="user.canAny(['update-settings'])"
                :data="{
                    overtime_warning_threshold: form.overtime_warning_threshold,
                    overtime_warning_interval: form.overtime_warning_interval
                }"
                :url="route('settings.update', { setting: 'gea_event', stay: true })"
                label="Opslaan"
                method="put"
            />
        </template>
    </Card>
</template>
