<script setup>
import Errors from '@/Components/Input/Errors.vue';
import Label from '@/Components/Input/Label.vue';
import theme from '@/formkitTheme';
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';

const props = defineProps({
    label: {},
    modelValue: {
        type: [Number, String],
        default: 0,
    },
    errors: Array,
    disabled: Boolean,
    help: String,
    numberOfDecimals: {
        type: [Number, String],
        default: 2,
    },
});
const emit = defineEmits(['update:modelValue', 'change']);

const locale = computed(() => usePage().props.locale);

const displayValue = computed(() => {
    if (locale.value == 'nl') {
        return String(props.modelValue.toFixed(props.numberOfDecimals))
            .replace(/[^\d.-]/g, '')
            .replace('.', ',');
    }
    return props.modelValue.toFixed(props.numberOfDecimals);
});

const emitValue = (value) => {
    value = value.target.value;

    let asString = String(value)
        .replace(',', '.')
        .replace(/[^\d.-]/g, '');

    let asFloat = parseFloat(asString);

    if (isNaN(asFloat)) {
        asFloat = 0;
    }

    emit('update:modelValue', asFloat);
    emit('change', asFloat);
};
</script>

<template>
    <div :class="theme.global.outer">
        <Label :label="label" />
        <div :class="theme.global.inner">
            <input
                :id="label ?? 'input'"
                type="text"
                :class="theme.global.input"
                :value="displayValue"
                :disabled="disabled"
                @blur="emitValue"
                @change="emitValue"
            >
        </div>
        <div
            v-if="help"
            :class="theme.global.help"
            v-html="help"
        />
        <Errors :error="errors" />
    </div>
</template>
