import {isObject} from "@vueuse/core";
import {twMerge} from "tailwind-merge";

export function blank(any: string | Array<string> | object | null): boolean {
    if (any === null) {
        return false;
    }

    if (typeof any === 'string') {
        return any === '';
    }

    if (Array.isArray(any)) {
        return any.length === 0;
    }

    if (typeof any === 'object') {
        return Object.keys(any).length === 0;
    }

    return false;
}

export function anyBlank(...args: Array<string | null>): boolean {
    return args.some((arg: string | null) => blank(arg));
}

export function extractError(errors: Record<string, string>, key: string): string[] {
    if (errors === undefined) {
        return [];
    }
    const error = errors[key];
    if (error === undefined) {
        return [];
    }
    console.log(`Error: ${error} for key: ${key}`);
    return [error];
}

export function microtime() {
    let now, multiplier;

    if (typeof performance !== 'undefined' && performance.now) {
        now = (performance.now() + performance.timing.navigationStart) / 1000;
        multiplier = 1e6; // 1,000,000 for microseconds
    } else {
        now = (Date.now ? Date.now() : new Date().getTime()) / 1000;
        multiplier = 1e3; // 1,000
    }


    // Dirty trick to only get the integer part
    const s = now | 0;

    return (Math.round((now - s) * multiplier) / multiplier);
}


export function classMerge(...args: Array<object | string | null>): string {
    args = args.map((arg: string | object | null) => isObject(arg) ? evalObject(arg) : arg) as Array<string | null>;

    return twMerge(args.filter((arg): boolean => !blank(arg)).join(' '));
}


export function evalObject(obj: object): Array<string> {
    const ans: Array<string> = [];

    Object.entries(obj).forEach(([key, value]) => {
        if (value) {
            ans.push(key);
        }
    });

    return ans;
}
