<script setup>
import theme from '@/formkitTheme';
import {Listbox, ListboxLabel, ListboxOption, ListboxOptions} from '@headlessui/vue';
import {XMarkIcon} from '@heroicons/vue/20/solid';
import {MagnifyingGlassIcon} from '@heroicons/vue/24/outline';
import {computed, ref, watch} from 'vue';
import Errors from '@/Components/Input/Errors.vue';
import Fuse from "fuse.js";

const props = defineProps({
    label: String,
    modelValue: String,
    options: Array,
    help: String,
    errors: Array,
    clearOnChange: Boolean,
    disabled: Boolean,
});

watch(() => props.modelValue, () => {
    if (props.modelValue) {
        selected.value = props.modelValue;
        searchTerm.value = props.modelValue;
    }
});

const emits = defineEmits(['update:modelValue', 'change', 'helpClicked']);

const open = ref(false);


const selected = ref('');
const searchTerm = ref('');

function emitValue(value) {
    searchTerm.value = value;
    emits('update:modelValue', value);
    emits('change');
    if (props.clearOnChange) {
        searchTerm.value = null;
        selected.value = null;
    }
}


const fuse = computed(() => new Fuse(props.options));
const results = ref(props.options.map((option) => ({item: option})));

watch([searchTerm, () => props.options], () => {
    if (searchTerm.value === '' || searchTerm.value === null) {
        results.value = props.options.map((option) => ({item: option}));
        return;
    }
    results.value = fuse.value.search(searchTerm.value, {});
    console.log(results.value);
});

const displayOptions = computed(() => results.value.map((el) => el.item));

const input = ref(null);

function clearAndFocus() {
    searchTerm.value = '';
    input.value.focus();
    selected.value = null;
    emits('update:modelValue', null);
    emits('change');
}

function tab() {
    open.value = false;
}

function blur() {
    open.value = false;
    emitValue(searchTerm.value);
}
</script>

<template>
    <div class="w-full">
        <Listbox
            v-model="selected"
            :class="theme.global.outer"
            as="div"
        >
            <ListboxLabel :class="theme.global.label">
                {{ label }}
            </ListboxLabel>
            <div :class="theme.global.inner">
                <div>
                    <input
                        id="account-number"
                        ref="input"
                        v-model="searchTerm"
                        :class="theme.global.input"
                        :disabled="disabled"
                        autocomplete="off"
                        name="account-number"
                        tabindex="-1"
                        type="text"
                        @blur="blur"
                        @focus="open = true"
                        @keydown.enter="emitValue(searchTerm)"
                        @keydown.esc="open = false"
                        @keydown.tab="tab()"
                    >
                    <div
                        v-if="searchTerm?.length > 0 && !disabled"
                        class="pointer-pointer absolute inset-y-0 right-0 m-1 flex items-center bg-white pr-2"
                        @click="clearAndFocus()"
                    >
                        <XMarkIcon
                            aria-hidden="true"
                            class="size-5 text-gray-400"
                        />
                    </div>
                    <div
                        v-else-if="!disabled"
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                        <MagnifyingGlassIcon
                            aria-hidden="true"
                            class="size-5 text-gray-400"
                        />
                    </div>
                </div>

                <transition
                    v-show="open"
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <ListboxOptions
                        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                        static
                    >
                        <ListboxOption
                            v-for="(option, key) in displayOptions"
                            :key="key"
                            v-slot="{ active, selected }"
                            :value="option"
                            as="template"
                        >
                            <li
                                :class="[
                                    active ? 'bg-pine-500 text-white' : 'text-gray-900',
                                    'relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-pine-500 hover:text-white',
                                ]"
                                @click="emitValue(option)"
                            >
                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                    {{ option }}
                                </span>
                            </li>
                        </ListboxOption>
                        <template v-if="!displayOptions.length">
                            <li class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900">
                                <span class="block truncate">Geen resultaten</span>
                            </li>
                        </template>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>

        <div
            v-if="help"
            :class="theme.global.help"
            @click="emits('helpClicked')"
            v-html="help"
        />
        <Errors :errors="errors" />
    </div>
</template>
