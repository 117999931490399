<script setup>
import FormButton from "@/Components/Buttons/FormButton.vue";
import TableCard from '@/Components/Table/TableCard.vue';
import {useUser} from "@/stores/userStore";
import {useForm, usePage} from '@inertiajs/vue3';
import {computed, inject} from 'vue';
import {useI18n} from "vue-i18n";
import {secondsToDuration} from "@/utilities";

const route = inject('route');

const {t} = useI18n();

const props = defineProps({
    query: Object,
    timeOffs: Object,
    user: Object,
    timeOffTypeOptions: Object,
});

const form = useForm({
    orderBy: props.query.orderBy ?? 'name',
    orderDir: props.query.orderDir ?? 'desc',
    page: props.query.page ?? 1,
    status: props.query.status ?? '',
});

const getData = (page = 1) => {
    form.page = page;

    form.get(usePage().props.route.current, {
        preserveState: true,
    });
};

const tableSettings = computed(() => [
    {label: t("Description"), width: '10%'},
    {label: t("Type"), width: '10%'},
    {label: t("Change"), cellType: 'duration', width: '10%'},
    {width: '1%', cellType: 'arrow',},
]);

const tableData = computed(() =>
    props.timeOffs.data.map((timeOff) => {
        return {
            id: timeOff.id,
            rowData: [
                timeOff.description,
                props.timeOffTypeOptions[timeOff.type],
                timeOff.change,
                route('time-offs.edit', timeOff.id),
            ],
        };
    })
);
const title = computed(() => {
    return t('Overtime Balance: {balance}', {balance: secondsToDuration(props.user.overtime_time_off)}) + ' / ' +
        t('Vacation Balance: {balance}', {balance: secondsToDuration(props.user.vacation_time_off)})
});

</script>

<template>
    <TableCard
        v-model:orderBy="form.orderBy"
        v-model:orderDir="form.orderDir"
        :data="timeOffs"
        :loading="form.processing"
        :table-data="tableData"
        :table-settings="tableSettings"
        :title="title"
        remove-pagination
        @update:order-by="getData()"
        @update:order-dir="getData()"
        @set-page="getData"
    >
        <template #top>
            <FormButton
                v-if="useUser().can('create-time-offs')"
                :label="$t('Add Time Off')"
                :url="route('time-offs.create',{user_id: user.id})"
                method="get"
            />
        </template>
    </TableCard>
</template>
