<script setup>
import Button from '@/Components/Buttons/Button.vue';
import Card from '@/Components/Card/Card.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import TimeOffForm from "@/Pages/TimeOff/Partials/TimeOffForm.vue";
import {useUser} from '@/stores/userStore';
import {mapErrors} from '@/utilities';
import {useForm} from '@inertiajs/vue3';
import {inject} from 'vue';

const route = inject('route');

const props = defineProps({
    query: Object,
    timeOff: Object,
    user: Object,
    timeOffTypeOptions: Object,
});

const form = useForm({
    user_id: props.user.id,
    description: '',
    change: '0',
    type: Object.keys(props.timeOffTypeOptions)[0],
    ...props.timeOff,
});

const submitForm = () => {
    form.post(route('time-offs.store'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <AppLayout>
        <Card :title="$t('Change time off {name}',{name: user.name})">
            <TimeOffForm
                :form="form"
                :time-off="timeOff"
                :time-off-type-options="timeOffTypeOptions"
            />

            <template #buttons>
                <Button
                    v-if="useUser().canAny(['create-time-offs'])"
                    :form="form"
                    label="Opslaan"
                    @click="submitForm"
                />
            </template>
        </Card>
    </AppLayout>
</template>
