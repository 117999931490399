<script lang="ts" setup>
import {inject, toRaw} from 'vue';

import Card from '@/Components/Card/Card.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import Divider from "@/Components/Divider.vue";
import Line from "@/Pages/Quotations/Lines/Line.vue";
import {useForm} from "@inertiajs/vue3";
import {mapErrors} from "@/utilities.js";
import {useQuotationLineStore} from "@/stores/quotationLinesStore";
import LineInput from "@/Pages/Quotations/Lines/LineInput.vue";
import MultiButton from "@/Components/Buttons/MultiButton.vue";
import {useUser} from "@/stores/userStore";
import {CategoryDto, ProductDto, QuotationDto} from "@t/generated";

const user = useUser();
const route = inject('route') as typeof import('ziggy-js').default;


const props = defineProps<{
    query: object;
    quotation: QuotationDto;
    products: ProductDto[];
    categories: CategoryDto[];
    vatRateOptions: object;
}>();

const store = useQuotationLineStore();

store.load(
    JSON.parse(JSON.stringify(toRaw(props.quotation))),
    JSON.parse(JSON.stringify(toRaw(props.products))),
    JSON.parse(JSON.stringify(toRaw(props.categories)))
);

const form = useForm({
    ...props.quotation,
    quotation_lines: []
});

function submitForm(action: string) {
    form.transform((data) => {
        console.log(route('quotations.update', {quotation: props.quotation.id, stay: true,}));
        return {...data, quotation_lines: [...store.quotation_lines]};
    })
        .put(route('quotations.update', {quotation: props.quotation.id, stay: true,}), {
            preserveState: true,
            preserveScroll: true,
            onError: (errors) => (form.errors = mapErrors(errors)),
            onSuccess: () => {
                if (action === 'save and preview') {
                    window.open(route('quotations.preview', {id: props.quotation.id}), '_blank');
                }
            },
        });
    return true;
}

</script>

<template>
    <AppLayout>
        <Card>
            <template #title>
                Offerte aanpassen
            </template>
            <template
                v-for="(lines, categoryName) in store.getLinesByCategory"
                :key="categoryName"
            >
                <Divider :title="categoryName" />
                <Line
                    v-for="line in lines"
                    :key="line.id"
                    :line="line"
                    :vat-rate-options="vatRateOptions"
                />
            </template>
            <LineInput />
            <template #buttons>
                <MultiButton
                    v-if="user.can('update-quotations')"
                    :form="form"
                    :items="[
                        { label: 'Opslaan en bekijken', value: 'save and preview' },
                    ]"
                    label="Opslaan"
                    @button-click="submitForm"
                />
            </template>
        </Card>
    </AppLayout>
</template>
