<script setup>
import Group from '@/Components/Input/Group.vue';
import Form from '@/Components/Input/Form.vue';
import {computed} from "vue";
import SelectSearch from "@/Components/Input/SelectSearch.vue";

const props = defineProps({
    form: Object,
    userOptions: Object,
    statusOptions: Object,
    leaveRegistration: Object,
    edit: Boolean,
    own: Boolean,
});


const closed = computed(() => {
    return props.own && props.leaveRegistration && props.leaveRegistration.status !== 'submitted';
});

</script>
<template>
    <Form>
        <Group
            v-if="!own"
            :cols="edit?2:1"
        >
            <SelectSearch
                v-model="form.user_id"
                :errors="form.errors?.user_id"
                :options="userOptions"
                clearable
                first-selected
                label="Werknemer"
                placeholder="Selecteer een werknemer"
            />

            <FormKit
                v-if="edit"
                v-model="form.status"
                :errors="form.errors?.status"
                :options="statusOptions"
                label="Status"
                type="select"
            />
        </Group>
        <Group>
            <FormKit
                v-model="form.description"
                :disabled="closed"
                :errors="form.errors?.description"
                label="Omschrijving"
                type="textarea"
            />
        </Group>
        <Group>
            <FormKit
                v-model="form.start_date"
                :errors="form.errors?.start_date"
                label="Start datum"
                type="date"
            />
            <FormKit
                v-model="form.end_date"
                :errors="form.errors?.end_date"
                label="Eind datum"
                type="date"
            />
        </Group>
        <Group>
            <FormKit
                v-model="form.hours_first_day"
                :disabled="closed"
                :errors="form.errors?.hours_first_day"
                label="Uren eerste dag"
                max="12"
                type="number"
            />
            <FormKit
                v-model="form.hours_last_day"
                :disabled="closed"
                :errors="form.errors?.hours_last_day"
                label="Uren laatste dag"
                max="12"
                type="number"
            />
        </Group>
    </Form>
</template>
