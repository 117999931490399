export default {
    global: {
        form: 'grid gap-y-2',
        outer: 'w-full',
        inner: ' relative',
        label: 'block text-sm font-medium text-pine-500 mb-1',
        input: 'relative block w-full h-10 bg-white rounded-md text-sm border border-gray-300 px-3 focus:border-pine-500 focus:ring-pine-500  text-left disabled:border-0 disabled:bg-grey-100',
        help: 'mt-0 text-sm text-gray-500',
        messages: 'list-none p-0 text-sm text-red-600',
        message: 'mt-2 ',
        prefixIcon: 'absolute top-1/2 -translate-y-1/2 w-6 left-3 pointer-events-none',
        suffixIcon: 'absolute top-1/2 -translate-y-1/2 w-6 right-3 pointer-events-none',
    },

    textarea: {
        input: 'h-32',
    },
    checkbox: {
        inner: '$reset',
        input: '$reset block h-6 w-6 rounded border-gray-300 text-pine-400 focus:ring-pine-400',
        wrapper: 'flex items-center gap-2 py-2',
        label: '$reset block leading-tight mb-0 select-none  text-sm cursor-pointer text-pine-500',
        decorator: 'hidden',
        legend: 'block mb-3 leading-none text-blue text-base',
    },
    select: {
        input: 'appearance-none ',
        selectIcon: 'absolute top-1/2 -translate-y-1/2 w-7 right-3 pointer-events-none',
    },
    // file: {
    //     fileItem: 'flex items-center text-gray-800 mb-1 last:mb-0',
    //     fileItemIcon: 'w-4 mr-2 shrink-0',
    //     fileList: 'shrink grow peer px-3 py-2 formkit-multiple:data-[has-multiple="true"]:mb-6',
    //     fileName: 'break-all grow text-ellipsis',
    //     fileRemove:
    //         'relative z-[2] ml-auto text-[0px] hover:text-red-500 pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-blue-500 peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-2 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
    //     fileRemoveIcon: 'block text-base w-3 relative z-[2]',
    //     inner: 'relative max-w-md cursor-pointer formkit-multiple:[&>button]:absolute',
    //     input: 'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
    //     noFiles: 'flex w-full items-center px-3 py-2 text-gray-400',
    //     noFilesIcon: 'w-4 mr-2',
    // },
    file: {
        wrapper: 'relative',
        input: '$reset absolute top-0 left-0 bottom-0 right-0 opacity-0',
        inner: 'w-full',
        prefixIcon: '$reset absolute top-1/2 -translate-y-1/2 w-6 left-0 pointer-events-none',
        outer: 'flex items-center',
        noFiles: 'pl-10',
        fileName: 'pl-10',
        // fileRemove: 'top-9 right-0 absolute text-red flex gap-2 items-center hover:text-red-dark',
        fileRemoveIcon: ' w-[16px]',
        help: 'pointer-events-none	',
    },
};
