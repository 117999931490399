<script setup>

import {durationToSeconds, secondsToDuration} from "@/utilities";
import {ref, watch} from "vue";

const props = defineProps({
    modelValue: [String, Number],
    label: String,
    placeholder: String,
    errors: Object,
});

const emits = defineEmits(['update:modelValue']);

const times = [...Array(400).keys()].map(hour => String(hour).padStart(2, 0)).map((hour) => [hour + ':00', hour + ':15', hour + ':30', hour + ':45',]).flat();
const timeOptions = [...times.map(time => time).reverse(), ...times.map(time => '- ' + time)].filter((time) => time !== '- 00:00');

const localValue = ref(secondsToDuration(props.modelValue));

watch(() => props.modelValue, (value) => {
    localValue.value = secondsToDuration(value);
});

watch(() => localValue.value, (value) => {
    emits('update:modelValue', durationToSeconds(value));
});

</script>

<template>
    <FormKit
        v-model="localValue"
        :errors="errors"
        :label="label"
        :options="timeOptions"
        :placeholder="placeholder"
        type="select"
    />
</template>
