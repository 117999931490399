<script setup>
import Card from '@/Components/Card/Card.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import AttendeeInput from '@/Pages/Events/Partials/AttendeeInput.vue';
import Quotation from '@/Pages/Events/Partials/Quotation.vue';
import ShowAttendances from '@/Pages/Events/Partials/ShowAttendances.vue';
import ShowEventDetails from '@/Pages/Events/Partials/ShowEventDetails.vue';
import TimeRegistrations from "@/Pages/Events/Partials/TimeRegistrations.vue";
import {useUser} from '@/stores/userStore';
import {ChevronRightIcon} from '@heroicons/vue/24/outline';
import {Link} from '@inertiajs/vue3';

const user = useUser();

const props = defineProps({
    event: Object,
    query: Object,
    selectedEventId: [Number, String],
    userOptions: Object,
    categoryInQuotation: Object,
    statusOptions: Object,
    contactOptions: Object,
    foodOptions: Object,
    drinkOptions: Object,
    templateOptions: Object,
    typeOptions: Object,
    areaOptions: Object,
    conflictingAttendances: Array,
    timeRegistrations: Object,
    timeRegistrationOptions: Object,
    totals: Object,
});

</script>

<template>
    <AppLayout>
        <Link

            :href="route('events.index',{date:event.start})"
            class="flex items-center text-pine-600"
        >
            <ChevronRightIcon
                class="w-10 rotate-180 rounded-full p-2 "
            />
            Terug
        </Link>

        <ShowEventDetails
            :event="event"
            :selected-event-id="selectedEventId"
            :totals="totals"
        />

        <ShowAttendances
            :area-options="areaOptions"
            :event="event"
            :selected-event-id="selectedEventId"
            :status-options="statusOptions"
            :user-options="userOptions"
        />

        <TimeRegistrations
            :query="query"
            :selected-event-id="selectedEventId"
            :time-registration-options="timeRegistrationOptions"
            :time-registrations="timeRegistrations"
        />

        <Card
            v-if="user.canAny(['accept-attendances', 'reject-attendances', 'invite-attendances'])"
            collapsable=""
            remove-padding=""
        >
            <template #title>
                {{
                    event.capacity
                        ? `Aanmeldingen ${event.accepted_attendances_count}/${event.capacity}`
                        : `Aanmeldingen`
                }}
            </template>

            <AttendeeInput
                :attendances="event.attendances"
                :conflicting-attendances="conflictingAttendances"
                :event="event"
                :selected-event-id="selectedEventId"
                :status-options="statusOptions"
                :user-options="Object.values(userOptions)"
            />
        </Card>

        <Quotation
            v-if="event.type == 'event'"
            :category-in-quotation="categoryInQuotation"
            :contact-options="contactOptions"
            :drink-options="drinkOptions"
            :event="event"
            :food-options="foodOptions"
            :status-options="statusOptions"
            :template-options="templateOptions"
            :type-options="typeOptions"
        />
    </AppLayout>
</template>
