<script lang="ts" setup>
import Errors from '@/Components/Input/Errors.vue';
import Label from '@/Components/Input/Label.vue';
import theme from '@/formkitTheme';
import {computed} from 'vue';


const props = defineProps<{
    label: string
    modelValue?: string,
    errors?: Array,
    disabled?: boolean,
    classes?: string,
}>();


const emits = defineEmits(['update:modelValue', 'change']);

function emitTime(event: object) {
    const el = event.target as HTMLInputElement

    let combined = date.value + ' ' + el.value;

    emits('update:modelValue', combined);
    emits('change');
}

function emitDate(event: object) {
    const el = event.target as HTMLInputElement

    let combined = el.value + ' ' + time.value;

    emits('update:modelValue', combined);
    emits('change', combined);
}

const date = computed(() => {
    if (typeof props.modelValue === "string") {
        return props.modelValue.split(' ')[0];
    }
    return null;
});

const time = computed(() => {
    if (typeof props.modelValue === "string") {
        return props.modelValue.split(' ')[1];
    }
    return null;
});

const timeOptions = [...Array(24).keys()].map(hour => String(hour).padStart(2, 0)).map((hour) => [hour + ':00', hour + ':15', hour + ':30', hour + ':45',]).flat();

</script>

<template>
    <div :class="theme.global.outer">
        <Label :label="label" />
        <div class="flex gap-2">
            <div :class="theme.global.inner + ' flex-1'">
                <input
                    :id="label ?? 'input'"
                    :class="theme.global.input + ' ' + classes"
                    :disabled="disabled"
                    :value="date"
                    type="date"
                    @blur="emitDate"
                    @change="emitDate"
                >
            </div>
            <div :class="theme.global.inner + ' w-23'">
                <select
                    :id="label ?? 'input'"
                    :class="theme.global.input + ' ' + classes"
                    :disabled="disabled"
                    :value="time"
                    @blur="emitTime"
                    @change="emitTime"
                >
                    <option
                        v-for="timeOption in timeOptions"
                        :key="timeOption"
                        :value="timeOption"
                    >
                        {{ timeOption }}
                    </option>
                </select>
            </div>
        </div>
        <Errors :errors="errors" />
    </div>
</template>
