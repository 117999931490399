<script setup>
import Button from '@/Components/Buttons/Button.vue';
import Form from '@/Components/Input/Form.vue';
import MultiButton from '@/Components/Buttons/MultiButton.vue';
import Card from '@/Components/Card/Card.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import EventForm from '@/Pages/Events/Partials/Forms/EventForm.vue';
import GAEForm from '@/Pages/Events/Partials/Forms/GaeForms.vue';
import ReminderForm from '@/Pages/Events/Partials/Forms/ReminderForm.vue';
import {mapErrors} from '@/utilities';
import {useForm} from '@inertiajs/vue3';
import {inject} from 'vue';

const route = inject('route');
const dayjs = inject('dayjs');

const props = defineProps({
    query: Object,
    typeOptions: Object,
    contactOptions: Object,
    quotationOptions: Object,
    repeatUnitOptions: Object,
    staffingLocations: Array,
    ownerOptions: Object,
});

const form = useForm({
    type: Object.keys(props.typeOptions)[0],
    contact_id: '',
    title: '',
    description: '',
    owner_ids: [],
    areas: [],
    postcode: '',
    street_name: '',
    street_number: '',
    street_number_addition: '',
    city: '',
    latitude: '',
    longitude: '',
    capacity: '',
    children: [],
    lines: [],

    ...props.query,
    start: props.query.start ? dayjs(props.query.start).format('YYYY-MM-DD 09:00') : dayjs().format('YYYY-MM-DD 09:00'),
    end: props.query.start ? dayjs(props.query.start).format('YYYY-MM-DD 17:00') : dayjs().format('YYYY-MM-DD 17:00'),
    all_day: props.query.all_day === 1,
    attachments: [],
    repeat: false,
    series_interval: 1,
    series_interval_unit: Object.keys(props.repeatUnitOptions)[0],
    series_end: '',

});

const submitForm = async (button = false) => {
    form.post(
        route('events.store', {
            invite: button === 'save and invite',
            invite_everybody: button === 'save and invite everybody',
        }),
        {
            preserveState: true,
            preserveScroll: true,
            onError: (errors) => (form.errors = mapErrors(errors)),
        }
    );
};
</script>

<template>
    <AppLayout trap-cursor>
        <Card>
            <template #title>
                Planner item aanmaken
            </template>
            <Form>
                <FormKit
                    v-model="form.type"
                    :errors="form.errors?.type"
                    :options="typeOptions"
                    :value="form.type"
                    label="Type"
                    type="select"
                    validate="required"
                />
                <ReminderForm
                    v-if="form.type === 'agenda_item' || form.type === 'personal_appointment'"
                    :form="form"
                    :owner-options="ownerOptions"
                    :repeat-unit-options="repeatUnitOptions"
                    :type-options="typeOptions"
                    create
                />
                <EventForm
                    v-if="form.type === 'event'"
                    :contact-options="contactOptions"
                    :form="form"
                    :owner-options="ownerOptions"
                    :quotation-options="quotationOptions"
                    :type-options="typeOptions"
                    create
                />
                <GAEForm
                    v-if="form.type === 'go_ahead_eagles'"
                    :contact-options="contactOptions"
                    :form="form"
                    :owner-options="ownerOptions"
                    :quotation-options="quotationOptions"
                    :staffing-locations="staffingLocations"
                    :type-options="typeOptions"
                    create
                />
            </Form>
            <template #buttons>
                <Button
                    v-if="form.type === 'agenda_item' || form.type === 'personal_appointment'"
                    :form="form"
                    label="Opslaan"
                    @click="submitForm"
                />

                <MultiButton
                    v-else
                    :form="form"
                    :items="[
                        { label: 'Opslaan en favorieten uitnodigen', value: 'save and invite' },
                        { label: 'Opslaan en iedereen uitnodigen', value: 'save and invite everybody' },
                    ]"
                    label="Opslaan"
                    @button-click="submitForm"
                />
            </template>
        </Card>
    </AppLayout>
</template>
