<script setup>
import Button from '@/Components/Buttons/Button.vue';
import Card from '@/Components/Card/Card.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import Form from '@/Pages/TimeRegistration/Partials/Form.vue';
import {useUser} from '@/stores/userStore';
import {mapErrors} from '@/utilities';
import {useForm} from '@inertiajs/vue3';
import {inject, onMounted} from 'vue';

const route = inject('route');
const dayjs = inject('dayjs');
const user = useUser();

const props = defineProps({
    query: Object,
    eventOptions: Object,
    statusOptions: Object,
});

const form = useForm({
    start_time: '00:00',
    end_time: '00:00',
    date: dayjs().format('YYYY-MM-DD'),
    break: '00:00',
    // duration_assembly: '00:00',
    // duration_disassembly: '00:00',
    // drinks: '',
    description: '',
    status: 'submitted',
    user_id: '',
    event_id: '',
    ...props.query,
});

const submitForm = () => {
    form.post(route('own-time-registrations.store'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};

onMounted(() => (form.user_id = user.id));
</script>

<template>
    <AppLayout>
        <Card>
            <template #title>
                Uren toevoegen
            </template>
            <Form
                :event-options="eventOptions"
                :form="form"
                :status-options="statusOptions"
                own
            />

            <template #buttons>
                <Button
                    v-if="useUser().canAny(['create-time-registrations', 'create-own-time-registrations'])"
                    :form="form"
                    label="Opslaan"
                    @click="submitForm"
                />
            </template>
        </Card>
    </AppLayout>
</template>
