import {useForm, usePage} from "@inertiajs/vue3";
import {inject} from "vue";

export function useIndex(propName: string, queryParameters: Array<string>, queryName = 'query') {
    const route = inject('route');

    queryParameters = [...queryParameters, 'search', 'page', 'orderBy', 'orderDir'];

    const defaultForm: Record<string, string | number> = {};

    queryParameters.forEach((parameter) => {
        defaultForm[parameter] = String(usePage().props.query[parameter]) || '';
    });

    const form = useForm(defaultForm);

    function getData(page = 1) {
        form.page = page;

        form.get(route(usePage().props.route.current_name), {
            preserveState: true,
            only: [propName, queryName],
        });
    }

    return {
        form,
        getData,
    }
}
