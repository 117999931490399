<script lang="ts" setup>
import Label from "@/Components/Input/Label.vue";
import SmallButton from "@/Components/Buttons/SmallButton.vue";
import {TrashIcon} from "@heroicons/vue/24/outline";
import Money from "@/Components/Input/Money.vue";
import Number from "@/Components/Input/Number.vue";
import {inject} from "vue";
import {route as routeFn} from 'ziggy-js';
import {extractError} from "@/typeSciptHelpers";
import {useQuotationLineStore} from "@/stores/quotationLinesStore";
import {QuotationLineDto} from "@t/generated";

const route = inject('route') as typeof routeFn;

const props = defineProps<{
    line: QuotationLineDto;
    vatRateOptions: object;
}>();

const store = useQuotationLineStore();

function submit() {
    console.log('submit');
}

</script>

<template>
    <div class="grid grid-cols-12 gap-4 gap-y-0 md:grid-cols-11">
        <FormKit
            v-model="line.description"
            :classes="{ outer: 'col-span-9 md:col-span-6' }"
            :errors="extractError(line.errors,'description')"
            label="Omschrijving"
            type="text"
            @update:model-value="submit"
        />

        <Number
            v-model="line.quantity"
            :errors="extractError(line.errors,'quantity')"
            class="col-span-3 md:col-span-1"
            label="Aantal"
            @update:model-value="store.calculateAmount(line.id)"
        />

        <Money
            v-model="line.price.price"
            :errors="extractError(line.errors,'price')"
            class="col-span-3 md:col-span-1"
            label="Prijs"
            @update:model-value="store.calculateAmount(line.id)"
        />

        <FormKit
            v-model="line.price.vat_rate"
            :classes="{ outer: 'col-span-4 md:col-span-1' }"
            :errors="extractError(line.errors,'vat_rate')"
            :options="vatRateOptions"
            label="BTW"
            name="vat_rate"
            type="select"
            @update:model-value="submit"
        />

        <div class="col-span-5 flex gap-4 md:col-span-2">
            <div cldhgass="flex-1">
                <Money
                    v-model="line.amount"
                    :errors="extractError(line.errors,'amount')"
                    classes="text-right"
                    label="Totaal"
                    @update:model-value="submit"
                />
            </div>
            <div class="mb-2 flex flex-col">
                <Label label="&nbsp;" />
                <SmallButton @click="store.destroy(line.id)">
                    <TrashIcon
                        aria-hidden="true"
                        class="size-5 text-white"
                    />
                </SmallButton>
            </div>
        </div>
    </div>
</template>
