<script setup>
import FormButton from '@/Components/Buttons/FormButton.vue';
import Card from '@/Components/Card/Card.vue';
import CardInset from '@/Components/Card/CardInset.vue';
import QuotationCategory from '@/Pages/Events/Partials/QuotationCategory.vue';
import Form from '@/Pages/Quotations/Partials/Form.vue';
import {useUser} from '@/stores/userStore';

const user = useUser();

const props = defineProps({
    event: Object,
    categoryInQuotation: Object,
    statusOptions: Object,
    contactOptions: Object,
    foodOptions: Object,
    drinkOptions: Object,
    templateOptions: Object,
    typeOptions: Object,
});
</script>
<template>
    <Card
        v-if="event.quotation"
        collapsable
        remove-padding
    >
        <template #title>
            <span class="text-white">Offerte - </span>{{ event.title }}
        </template>

        <CardInset>
            <Form
                :contact-options="contactOptions"
                :drink-options="drinkOptions"
                :food-options="foodOptions"
                :form="{ ...event.quotation, ...event.quotation.address }"
                :template-options="templateOptions"
                :type-options="typeOptions"
                disabled
            />
        </CardInset>

        <template
            v-for="category in categoryInQuotation"
            :key="category.id"
        >
            <QuotationCategory
                :category="category"
                :quotation-lines="event.quotation.quotation_lines"
            />
        </template>

        <template
            v-if="user.canAny(['update-quotations'])"
            #buttons
        >
            <FormButton
                v-if="user.can('update-quotations')"
                :url="route('quotations.update', event.quotation.id)"
                label="Aanpassen"
                method="get"
            />
        </template>
    </Card>
</template>
