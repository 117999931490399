<script setup>
import {useUser} from '@/stores/userStore';
import {
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue';
import {
    AcademicCapIcon,
    Bars3Icon,
    CalendarDaysIcon,
    ClockIcon,
    Cog6ToothIcon,
    HomeIcon,
    ListBulletIcon,
    MagnifyingGlassIcon,
    ShoppingCartIcon,
    TagIcon,
    UserIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/vue/24/outline';
import logoMobile from '@i/logo-mobile.svg';
import logo from '@i/logo.svg';
import {Head, Link, usePage} from '@inertiajs/vue3';
import {onClickOutside, onKeyStroke, useEventBus} from '@vueuse/core';
import {inject, onMounted, ref, useSlots} from 'vue';

// Components
import SideOver from '@/Components/Layouts/SideOver.vue';
import CommandPalette from '@/Components/Modal/CommandPalette.vue';
import Navigation from '@/Components/Navigation.vue';
import Notifications from '@/Components/Notifications.vue';
import ClickAwayModal from '@/Layouts/Partials/ClickAwayModal.vue';
import {useI18n} from 'vue-i18n';
import {SunIcon} from "@heroicons/vue/24/outline/index.js";

const {t} = useI18n();

const props = defineProps({
    title: String,
    description: String,
    removePadding: Boolean,
    disableScroll: Boolean,
    trapCursor: Boolean,
});

const user = useUser();
const route = inject('route');

const showCommandPalette = ref(false);
const bus = useEventBus('command-palette');

bus.on(() => {
    showCommandPalette.value = true;
});

onKeyStroke(['/'], (e) => {
    if (e.ctrlKey) {
        showCommandPalette.value = true;
    }
});
onKeyStroke(['k'], (e) => {
    if (e.metaKey) {
        showCommandPalette.value = true;
    }
});

const navigation = [
    [
        {
            name: 'Agenda',
            href: route('events.index'),
            icon: HomeIcon,
            current: route().current('events.*'),
            permission: ['viewAny-events', 'view-events'],
        },
        {
            name: 'Contacten',
            href: route('contacts.index'),
            icon: UsersIcon,
            current: route().current('contacts.*'),
            permission: 'viewAny-contacts',
        },
        {
            name: 'Producten',
            href: route('products.index'),
            icon: ShoppingCartIcon,
            current: route().current('products.*'),
            permission: 'viewAny-products',
        },
        {
            name: 'Categorieën',
            href: route('categories.index'),
            icon: TagIcon,
            current: route().current('categories.*'),
            permission: 'viewAny-categories',
        },
        {
            name: 'Offertes',
            href: route('quotations.index'),
            icon: ListBulletIcon,
            current: route().current('quotations.*'),
            permission: 'viewAny-quotations',
        },

    ],
    [
        {
            name: 'Uren registratie',
            href: route('time-registrations.index'),
            icon: ClockIcon,
            current: route().current('time-registrations.*'),
            permission: ['viewAny-time-registrations'],
        },
        {
            name: 'Verlofregistratie',
            href: route('leave-registrations.index'),
            icon: SunIcon,
            current: route().current('leave-registrations.*'),
            permission: ['viewAny-leave-registrations'],
        },
        {
            name: 'Mijn Uren registratie',
            href: route('own-time-registrations.index'),
            icon: ClockIcon,
            current: route().current('own-time-registrations.*'),
            permission: ['view-own-time-registrations'],
            hideOnPermission: ['viewAny-time-registrations']
        },
        {
            name: 'Mijn Verlofregistratie',
            href: route('own-leave-registrations.index'),
            icon: SunIcon,
            current: route().current('own-leave-registrations.*'),
            permission: ['view-own-leave-registrations'],
            hideOnPermission: ['viewAny-leave-registrations']
        },
    ],
    [
        {
            name: 'Gebruikers',
            href: route('users.index'),
            icon: UsersIcon,
            current: route().current('users.index'),
            permission: 'viewAny-users',
        },
        {
            name: 'Vaardigheden',
            href: route('skills.index'),
            icon: AcademicCapIcon,
            current: route().current('skills.index'),
            permission: 'viewAny-users',
        },
        {
            name: 'Instellingen',
            href: route('settings.index'),
            icon: Cog6ToothIcon,
            current: route().current('settings.index'),
            permission: 'viewAny-settings',
        },
    ],
];
const userNavigation = [
    {
        name: t('Leave Impersonating'),
        href: route('impersonate.leave'),
        hide: !user.impersonating
    },
    {name: t('Your Profile'), href: route('profile.edit')},
    {name: t('Sign out'), href: route('logout'), method: 'post'},
];


const allowedUserNavigation = ref([]);
onMounted(() => {
    allowedUserNavigation.value = userNavigation.filter((item) => !(
        (typeof item.permission !== 'undefined' && !user.canAny(item.permission))
        || (typeof item.missingPermission !== 'undefined' && user.canAny(item.missingPermission))
        || (typeof item.hide !== 'undefined' && item.hide)
    ));
});


const sidebarOpen = ref(false);

const slots = useSlots();

const slideOverOpen = ref(false);

onMounted(() => {
    user.load(usePage().props.auth.user)
});

const trappedCursor = ref(props.trapCursor);
const showTrapModal = ref(false);
const main = ref(null);
const lastXtarget = ref(null);
const lastYtarget = ref(null);

onClickOutside(
    main,
    (event) => {
        event.preventDefault();

        lastXtarget.value = event.target.clientHeight;
        lastYtarget.value = event.target.clientWidth;

        if (trappedCursor.value) {
            showTrapModal.value = true;
        }
    },
    {capture: true}
);

function continueClick() {
    showTrapModal.value = false;
    trappedCursor.value = false;
}
</script>

<template>
    <Head>
        <title>{{ title ?? 'DHG Planner' }}</title>
        <meta
            :content="description"
            head-key="description"
            name="description"
        >
    </Head>
    <Notifications />
    <div
        :class="{ 'pointer-events-none': trappedCursor }"
        class="h-full"
    >
        <!-- Mobile menu -->
        <TransitionRoot
            :show="sidebarOpen"
            as="template"
        >
            <Dialog
                as="div"
                class="relative z-40 lg:hidden"
                @close="sidebarOpen = false"
            >
                <TransitionChild
                    as="template"
                    enter="transition-opacity ease-linear duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div class="fixed inset-0 bg-gray-600/75" />
                </TransitionChild>

                <div class="fixed inset-0 z-40 flex">
                    <TransitionChild
                        as="template"
                        enter="transition ease-in-out duration-300 transform"
                        enter-from="-translate-x-full"
                        enter-to="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leave-from="translate-x-0"
                        leave-to="-translate-x-full"
                    >
                        <DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col bg-pine-500 px-8">
                            <TransitionChild
                                as="template"
                                enter="ease-in-out duration-300"
                                enter-from="opacity-0"
                                enter-to="opacity-100"
                                leave="ease-in-out duration-300"
                                leave-from="opacity-100"
                                leave-to="opacity-0"
                            >
                                <div class="absolute right-15 top-0 -mr-12 pt-2">
                                    <button
                                        class="ml-1 flex size-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pine-500"
                                        type="button"
                                        @click="sidebarOpen = false"
                                    >
                                        <span class="sr-only">Close sidebar</span>
                                        <XMarkIcon
                                            aria-hidden="true"
                                            class="size-6 text-beige-500"
                                        />
                                    </button>
                                </div>
                            </TransitionChild>
                            <div class="h-0 flex-1 overflow-y-auto pb-4 pt-5">
                                <!-- LOGO -->
                                <Link
                                    class="flex shrink-0 items-center px-4"
                                    href="/"
                                >
                                    <img
                                        :src="logo"
                                        alt="DHG Planner"
                                        class="mx-auto h-12 w-auto"
                                    >
                                </Link>

                                <Navigation :navigation="navigation" />
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                    <div class="w-14 shrink-0">
                        <!-- Force sidebar to shrink to fit close icon -->
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
        <!-- Mobile menu -->

        <!-- Static sidebar for desktop -->
        <div class="z-10 hidden lg:fixed lg:inset-y-0 lg:flex lg:w-[320px] lg:flex-col">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex min-h-0 flex-1 flex-col border-r border-beige-500 bg-pine-500 px-8">
                <div class="flex flex-1 flex-col overflow-y-auto pb-4 pt-5">
                    <!-- LOGO -->
                    <Link
                        class="flex shrink-0 items-center"
                        href="/"
                    >
                        <img
                            :src="logo"
                            alt="DHG Planner"
                            class="mx-auto h-16 w-auto"
                        >
                    </Link>

                    <Navigation :navigation="navigation" />
                </div>
            </div>
        </div>

        <div class="relative flex h-full flex-1 flex-col lg:pl-[320px]">
            <div
                class="sticky inset-x-0 top-0 z-10 flex h-16 shrink-0 justify-between bg-pine-500 shadow"
            >
                <button
                    class="px-4 text-pine-700 ring-offset-transparent focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pine-500 lg:hidden"
                    type="button"
                    @click="sidebarOpen = true"
                >
                    <span class="sr-only">Open sidebar</span>
                    <Bars3Icon
                        aria-hidden="true"
                        class="size-6 text-beige-500"
                    />
                </button>

                <Link
                    class="flex flex-1 shrink-0 items-center lg:hidden"
                    href="/"
                >
                    <img
                        :src="logoMobile"
                        alt="DHG Planner"
                        class="mx-auto h-8 w-auto"
                    >
                </Link>

                <div class="hidden lg:block" />

                <div class="flex items-center pr-4">
                    <div class="hidden tracking-wide text-white md:block">
                        {{ user.first_name }}
                    </div>

                    <Menu
                        as="div"
                        class="relative ml-3"
                    >
                        <div>
                            <MenuButton
                                class="flex max-w-xs items-center rounded-full bg-pine-800 p-2 text-sm ring-offset-pine-500 focus:outline-none focus:ring-2 focus:ring-beige-500 focus:ring-offset-2"
                            >
                                <span class="sr-only">Open user menu</span>
                                <UserIcon class="size-6 text-beige-500" />
                            </MenuButton>
                        </div>
                        <transition
                            enter-active-class="transition duration-100 ease-out"
                            enter-from-class="transform scale-95 opacity-0"
                            enter-to-class="transform scale-100 opacity-100"
                            leave-active-class="transition duration-75 ease-in"
                            leave-from-class="transform scale-100 opacity-100"
                            leave-to-class="transform scale-95 opacity-0"
                        >
                            <MenuItems
                                class="absolute right-0 z-40 mt-2 w-48 origin-top-right rounded-md bg-pine-500 py-1 shadow-lg shadow-beige-100 ring-1 ring-black/5 focus:outline-none"
                            >
                                <MenuItem
                                    v-for="item in allowedUserNavigation"
                                    :key="item.name"
                                    v-slot="{ active }"
                                >
                                    <Link
                                        :as="item?.method === 'get' ? 'a' : 'button'"
                                        :class="[
                                            active ? 'bg-pine-800' : '',
                                            'block w-full px-4 py-2 text-left text-sm text-beige-500',
                                        ]"
                                        :href="item.href"
                                        :method="item.method ?? 'get'"
                                    >
                                        {{ item.name }}
                                    </Link>
                                </MenuItem>
                            </MenuItems>
                        </transition>
                    </Menu>
                </div>
            </div>

            <div class="flex size-full">
                <main
                    ref="main"
                    :class="{ 'hide-scrollbar hide-scroll overflow-y-scroll': !disableScroll }"
                    class="pointer-events-auto relative size-full flex-1"
                >
                    <button
                        v-if="route().current('events.index') && user.can('viewAny-events')"
                        class="absolute right-0 top-12 z-10 flex size-12 items-center justify-center rounded-l-lg bg-white shadow xl:hidden"
                        @click="showCommandPalette = true"
                    >
                        <MagnifyingGlassIcon
                            aria-hidden="true"
                            class="size-6 text-beige-500"
                        />
                    </button>
                    <button
                        v-if="slots.aside"
                        class="absolute right-0 top-30 z-10 flex size-12 items-center justify-center rounded-l-lg bg-white shadow xl:hidden"
                        @click="slideOverOpen = true"
                    >
                        <CalendarDaysIcon
                            aria-hidden="true"
                            class="size-6 text-beige-500"
                        />
                    </button>

                    <hr>
                    <div
                        v-if="!disableScroll"
                        class="h-8"
                    />
                    <div
                        :class="{ 'h-full ': disableScroll, '': !disableScroll }"
                        class="relative mx-auto flex max-w-9xl flex-col gap-6 px-4 sm:px-6 lg:gap-8 lg:px-8 xl:px-16 2xl:px-32"
                    >
                        <slot />
                    </div>
                    <div
                        v-if="!disableScroll"
                        class="h-80"
                    />
                </main>
                <aside
                    v-if="slots.aside"
                    class="relative hidden w-96 shrink-0 overflow-y-auto border-l border-gray-200 xl:flex xl:flex-col"
                >
                    <!-- Start secondary column (hidden on smaller screens) -->
                    <div class="absolute inset-0 bg-beige-500 px-4 py-6 sm:px-6 lg:px-8">
                        <slot name="aside" />
                    </div>
                    <!-- End secondary column -->
                </aside>

                <SideOver v-model="slideOverOpen">
                    <slot name="aside" />
                </SideOver>
            </div>
        </div>
    </div>
    <ClickAwayModal
        v-model="showTrapModal"
        @confirm="continueClick"
    />
    <CommandPalette v-model="showCommandPalette" />
</template>
