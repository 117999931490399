<script setup>
import {mapErrors} from '@/utilities';
import {useForm} from '@inertiajs/vue3';
import {inject} from 'vue';

import Button from '@/Components/Buttons/Button.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import Form from '@/Pages/Categories/Partials/Form.vue';

import ConformationButton from '@/Components/Buttons/ConformationButton.vue';
import Card from '@/Components/Card/Card.vue';
import {useUser} from '@/stores/userStore';

const route = inject('route');
const user = useUser();

const props = defineProps({
    category: Object,
    categoryOptions: Object,
    roleOptions: Object,
});

const form = useForm({
    title: '',
    category_id: null,
    ...props.category,
});

const submitForm = () => {
    form.transform((data) => ({
        ...data,
        category_id: parseInt(data.category_id),
    })).put(route('categories.update', props.category.id), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <AppLayout>
        <Card dark>
            <template #title>
                Categorie aanpassen
            </template>

            <div class="grid grid-cols-1 gap-2">
                <Form
                    :category-options="categoryOptions"
                    :form="form"
                    :role-options="roleOptions"
                />
            </div>

            <template #buttons>
                <ConformationButton
                    v-if="user.can('delete-categories')"
                    :url="route('categories.destroy', category.id)"
                    conformation-message="Weet je zeker dat je deze categorie wilt verwijderen?"
                    conformation-title="Verwijderen"
                    label="Verwijderen"
                    method="delete"
                />
                <Button
                    v-if="user.can('update-categories')"
                    :form="form"
                    label="Aanpassen"
                    @click="submitForm"
                />
            </template>
        </Card>
    </AppLayout>
</template>
