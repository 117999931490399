<script setup>
import PageTitle from '@/Components/Page/Title.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import {useForm} from '@inertiajs/vue3';
import GaeStaffingLocations from '@/Pages/Settings/Partials/GaeStaffingLocations.vue';
import GaeTemplate from '@/Pages/Settings/Partials/GaeTemplate.vue';
import GaeWhatOptions from '@/Pages/Settings/Partials/GaeWhatOptions.vue';
import GaeWhoOptions from '@/Pages/Settings/Partials/GaeWhoOptions.vue';
import TimeRegisrtrations from "@/Pages/Settings/Partials/TimeRegistrations.vue";

const props = defineProps({
    settings: Object,
    timeRegistrationsSettings: Object,
});

const form = useForm({
    schema: props.settings.template,
    whoOptions: props.settings.whoOptions,
    whatOptions: props.settings.whatOptions,
    staffingLocations: props.settings.staffingLocations,
    overtime_warning_threshold: props.timeRegistrationsSettings.overtime_warning_threshold,
    overtime_warning_interval: props.timeRegistrationsSettings.overtime_warning_interval,
});
</script>

<template>
    <AppLayout>
        <PageTitle title="Instellingen" />
        <GaeTemplate
            :form="form"
            :what-options="settings.whatOptions"
            :who-options="settings.whoOptions"
        />
        <GaeWhoOptions :form="form" />
        <GaeWhatOptions :form="form" />
        <GaeStaffingLocations :form="form" />
        <TimeRegisrtrations :form="form" />
    </AppLayout>
</template>
