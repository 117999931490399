<script setup>
import Card from '@/Components/Card/Card.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import {Head} from '@inertiajs/vue3';
import {inject} from 'vue';

const route = inject('route');

const props = defineProps({
    query: Object,
});
</script>

<template>
    <GuestLayout>
        <Head title="Register" />
        <Card> Bedankt voor het registreren.</Card>
    </GuestLayout>
</template>
