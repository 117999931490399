<script setup>
import Button from '@/Components/Buttons/Button.vue';
import ConformationButton from '@/Components/Buttons/ConformationButton.vue';
import Card from '@/Components/Card/Card.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import LeaveRegistrationForm from '@/Pages/LeaveRegistration/Partials/LeaveRegistrationForm.vue';
import {useUser} from '@/stores/userStore';
import {mapErrors} from '@/utilities';
import {useForm} from '@inertiajs/vue3';
import {inject} from 'vue';

const route = inject('route');
const user = useUser();

const props = defineProps({
    query: Object,
    leaveRegistration: Object,
    userOptions: Object,
    statusOptions: Object,
});

const form = useForm({
    date: '',
    start_time: '',
    end_time: '',
    break: '00:00',
    description: '',
    dates: [
        props.leaveRegistration.start_date,
        props.leaveRegistration.end_date
    ],
    user_id: '',
    ...props.leaveRegistration,
});

const submitForm = () => {
    form.put(route('leave-registrations.update', props.leaveRegistration.id), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <AppLayout>
        <Card>
            <template #title>
                Uren aanpassen
            </template>
            <LeaveRegistrationForm
                :form="form"
                :status-options="statusOptions"
                :user-options="userOptions"
                edit
            />

            <template #buttons>
                <ConformationButton
                    v-if="user.canAny(['delete-leave-registrations'])"
                    :url="route('leave-registrations.destroy', leaveRegistration.id)"
                    conformation-message="Weet je zeker dat je deze tijdregistratie wilt verwijderen?"
                    conformation-title="Verwijderen"
                    label="Verwijderen"
                    method="delete"
                />
                <Button
                    v-if="user.canAny(['create-leave-registrations'])"
                    :form="form"
                    label="Opslaan"
                    @click="submitForm"
                />
            </template>
        </Card>
    </AppLayout>
</template>
