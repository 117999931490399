<script setup>
import FormButton from '@/Components/Buttons/FormButton.vue';
import InputGroup from '@/Components/Input/Group.vue';
import PageTitle from '@/Components/Page/Title.vue';
import Pagination from '@/Components/Pagination.vue';
import Table from '@/Components/Table/Table.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import {useUser} from '@/stores/userStore';
import {useForm} from '@inertiajs/vue3';
import {useDebounceFn} from '@vueuse/shared';
import {computed, inject} from 'vue';

const route = inject('route');
const user = useUser();

const props = defineProps({
    query: Object,
    products: Object,
});

const form = useForm({
    orderBy: props.query.orderBy ?? 'title',
    orderDir: props.query.orderDir ?? 'desc',
    search: props.query.search ?? '',
    page: props.query.page ?? 1,
});

const getData = (page = 1) => {
    form.page = page;

    form.get(route('products.index'), {
        preserveState: true,
        only: ['products', 'query'],
    });
};
const debouncedGetData = useDebounceFn(() => getData(), 300);

const tableSettings = computed(() => [
    {label: 'Titel', orderBy: 'title', width: '15%'},
    {label: 'Categorieën', width: '15%', from: 'md', orderBy: 'category_title'},
    {label: 'BTW', width: '15%', permission: user.can('view-prices'), from: 'md'},
    {label: 'Prijs', width: '5%', cellType: 'money', permission: user.can('view-prices'), from: 'md'},
    {label: '', width: '1%', cellType: 'arrow', permission: user.can('update-products')},
]);

const tableData = computed(() =>
    props.products.data.map((product) => {
        return {
            id: product.id,
            rowData: [
                product.title,
                product.categories.map((category) => ' ' + category.title).toString(),
                product.vat_rate,
                product?.price.price,
                route('products.edit', product.id),
            ],
        };
    })
);
</script>

<template>
    <AppLayout>
        <PageTitle title="Producten">
            <FormButton
                v-if="user.can('create-products')"
                :url="route('products.create')"
                label="Product toevoegen"
                method="get"
            />
        </PageTitle>
        <Table
            v-model:orderBy="form.orderBy"
            v-model:orderDir="form.orderDir"
            :loading="form.processing"
            :table-data="tableData"
            :table-settings="tableSettings"
            @update:order-by="getData()"
            @update:order-dir="getData()"
        >
            <template #filters>
                <InputGroup>
                    <FormKit
                        v-model="form.search"
                        :errors="form.errors?.search"
                        :value="form.search"
                        label="Zoeken"
                        suffix-icon="search"
                        type="text"
                        @input="debouncedGetData"
                    />
                </InputGroup>
            </template>

            <Pagination
                :page-data="products"
                @set-page="getData"
            />
        </Table>
    </AppLayout>
</template>
