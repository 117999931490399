<script setup>
import Button from '@/Components/Buttons/Button.vue';
import Card from '@/Components/Card/Card.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import {useUser} from '@/stores/userStore';
import {mapErrors} from '@/utilities';
import {useForm} from '@inertiajs/vue3';
import {inject} from 'vue';
import LeaveRegistration from '@/Pages/LeaveRegistration/Partials/LeaveRegistrationForm.vue';

const route = inject('route');

const props = defineProps({
    query: Object,
    userOptions: Object,
});

const form = useForm({
    description: '',
    start_date: '00:00',
    end_date: '00:00',
    dates: null,
    hours_first_day: '00:00',
    hours_last_day: '00:00',
    total_hours: '00:00',
    user_id: '',
    ...props.query,
});

const submitForm = () => {
    form.post(route('leave-registrations.store'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};

</script>

<template>
    <AppLayout>
        <Card>
            <template #title>
                {{ $t('Leave registrations') }}
            </template>

            <LeaveRegistration
                :form="form"
                :user-options="userOptions"
            />

            <template #buttons>
                <Button
                    v-if="useUser().canAny(['create-time-registrations'])"
                    :form="form"
                    label="Opslaan"
                    @click="submitForm"
                />
            </template>
        </Card>
    </AppLayout>
</template>
