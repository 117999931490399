<script lang="ts" setup>

import {computed, inject} from "vue";
import {ClockIcon, MapIcon, UserIcon} from "@heroicons/vue/24/outline";
import {useUser} from "@/stores/userStore";
import {Dayjs} from 'dayjs';

const dayjs = inject('dayjs') as Dayjs;
const user = useUser();

const props = defineProps<{
    date: string,
    event: {
        type: object,
        required: true,
    },
}>()

const shouldShowTime = computed(() => {
    return user.can('viewAny-events') || userAttendance.value != null;
});

const userAttendance = computed(() => {
    if (typeof props.event.accepted_attendances == 'undefined') {
        return null;
    }

    const userAttendances = props.event.accepted_attendances.filter((a) => a.user_id == user.id);

    return userAttendances.length == 0 ? null : userAttendances[0];
});


const start = computed(() => {
    if (user.can('viewAny-events')) {
        return dayjs(props.event.start);
    }

    return userAttendance.value == null ? null : dayjs(userAttendance.value.start);
});

const end = computed(() => {
    if (user.can('viewAny-events')) {
        return dayjs(props.event.end);
    }
    return userAttendance.value == null ? null : dayjs(userAttendance.value.end);
});

</script>

<template>
    <div class="flex flex-col flex-wrap gap-x-4 gap-y-2 text-sm text-white lg:flex-row">
        <div
            v-if="shouldShowTime"
            class="flex items-center gap-2 whitespace-nowrap"
        >
            <ClockIcon class="inline-block size-4" />
            <div v-if="event.all_day">
                Hele dag &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div v-else-if="!start.isSame(date,'day')">
                {{ start.format('HH:mm') }} <strong>{{ start.format('D MMMM YYYY') }}</strong> -
                {{ end.format('HH:mm D MMMM YYYY') }}
            </div>
            <div v-else-if="start.isSame(date,'day') && !end.isSame(date,'day') ">
                {{ start.format('HH:mm') }} - {{ end.format('D MMMM YYYY HH:mm') }}
            </div>
            <div v-else-if="end.isSame(date,'day') && !start.isSame(date,'day') ">
                {{ start.format('D MMMM YYYY HH:mm') }} - {{ end.format('HH:mm') }}
            </div>
            <div v-else-if="!start.isSame(end,'day')">
                Hele dag &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div v-else-if="start">
                {{ start.format('HH:mm') }} - {{ end.format('HH:mm') }}
            </div>
        </div>

        <div
            v-if="event.capacity !== null"
            class="flex items-center gap-2"
        >
            <UserIcon class="inline-block size-4" />
            <div>{{ event.accepted_attendances?.length ?? 0 }}/{{ event.capacity }}</div>
        </div>

        <a
            v-if="event.address"
            :href="event.address.url"
            class="group col-span-2 flex cursor-pointer items-center gap-2 hover:underline md:col-span-3"
            target="_blank"
        >
            <MapIcon class="inline-block size-4" />
            <div class="group-hover:underline">
                <span class="inline-block">
                    {{ event.address.street_name }} {{ event.address.street_number }}
                    {{ event.address.street_number_addition }}, &nbsp;
                </span>
                <span class="inline-block"> {{ event.address.postcode }} {{ event.address.city }} </span>
            </div>
        </a>

        <div
            v-if="event.type == 'go_ahead_eagles'"
            class="flex items-center gap-2"
        >
            GAE
            <span
                v-if="typeof event.areas != 'undefined' && event.areas != null && event.areas.length > 0"
                class=""
            >
                - {{ event.areas.join(', ') }}
            </span>
        </div>
    </div>
</template>

