<script setup>
import {mapErrors} from '@/utilities';
import {useForm} from '@inertiajs/vue3';
import {inject} from 'vue';

import Button from '@/Components/Buttons/Button.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import Form from '@/Pages/Users/Partials/Form.vue';

import ConformationButton from '@/Components/Buttons/ConformationButton.vue';
import Card from '@/Components/Card/Card.vue';
import {useUser} from '@/stores/userStore';
import UserTimeOff from "@/Pages/Users/Partials/UserTimeOff.vue";
import FormButton from "@/Components/Buttons/FormButton.vue";

const route = inject('route');
const currentUser = useUser();

const props = defineProps({
    user: Object,
    roleOptions: Object,
    drivingLicenseOptions: Object,
    skillOptions: Object,
    ownerOptions: Object,
    timeOffs: Object,
    query: Object,
    timeOffTypeOptions: Object,
});

const form = useForm({
    active: true,
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    role: '',
    driving_licenses: [],
    approved: false,
    favoriet: false,
    permanent_staff_member: false,
    skills: [],
    color_code: null,
    work_hours_per_week: 40,
    vacation_hours_per_year: 100,
    ...props.user,
});

const submitForm = () => {
    form.put(route('users.update', props.user.id), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <AppLayout>
        <Card>
            <template #title>
                Gebruiker aanpassen
            </template>
            <Form
                :driving-license-options="drivingLicenseOptions"
                :form="form"
                :role-options="roleOptions"
                :skill-options="skillOptions"
            />
            <template #buttons>
                <FormButton
                    v-if="currentUser.can('impersonate')"
                    :url="route('impersonate', user.id)"
                    label="Impersoneer"
                />

                <Button
                    v-if="currentUser.can('update-users')"
                    :form="form"
                    label="Aanpassen"
                    @click="submitForm"
                />

                <ConformationButton
                    v-if="currentUser.can('delete-users')"
                    :url="route('users.destroy', user.id)"
                    conformation-message="Weet je zeker dat je deze gebruiker wilt verwijderen?"
                    conformation-title="Gebruiker verwijderen"
                    label="Verwijderen"
                    method="delete"
                />
            </template>
        </Card>

        <UserTimeOff
            :query="query"
            :time-off-type-options="timeOffTypeOptions"
            :time-offs="timeOffs"
            :user="user"
        />
    </AppLayout>
</template>
