import DataObjectParser from 'dataobject-parser';
import {pickBy} from 'lodash';

export function objectMap(obj, fn) {
    return Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));
}

export function arrayUniqueByKey(array, key = 'id') {
    return [...new Map(array.map(item => [item[key], item])).values()];
}

export function sortObjectByKeys(obj) {
    return Object.keys(obj).sort().reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
    }, {});

}

export function mapErrors(errors, remap = {}) {
    errors = objectMap(errors, (error) => [error]);
    errors = DataObjectParser.transpose(errors).data();

    for (const [key, value] of Object.entries(remap)) {
        if (Object.hasOwnProperty.call(remap, key)) {
            errors[key] = errors[value];
        }
    }
    console.table(errors);
    return errors;
}

export function filterEmpty(data) {
    return pickBy(data, (v, k, i) => v !== '' && v !== null);
}

export function getTimesBetween(start, end, step = 'day', stepSize = 1) {
    let localStart = start;
    let dates = [];
    while (localStart.isBefore(end)) {
        dates.push(localStart);
        localStart = localStart.add(stepSize, step);
    }
    return dates;
}

export function pick(obj, fields) {
    return fields.reduce((acc, cur) => ((acc[cur] = obj[cur]), acc), {});
}

export function capitalFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getVat(product) {
    let price = parseInt(product?.price ?? product.unit_price ?? product?.price.price ?? 0);
    let vat = parseInt(product?.vat_rate ?? 0);
    let priceIncludesVat = product?.price_includes_vat ?? product?.price.price_including_vat ?? true;

    if (priceIncludesVat) {
        return Math.round((price * vat) / (100 + vat));
    }

    return Math.round(price * (vat / 100));
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toSelect(objectOrArray, nullLabel = 'Select an option', nullValue = '', emptyLabel = 'No options available') {
    if (Array.isArray(objectOrArray)) {
        if (objectOrArray.length === 0) {
            return [{label: emptyLabel, value: nullValue}];
        }

        return [{label: nullLabel, value: nullValue}, ...objectOrArray];
    }

    if (Object.keys(objectOrArray).length === 0) {
        return [{label: emptyLabel, value: nullValue}];
    }

    return {[nullValue]: nullLabel, ...objectOrArray};
}

export function ucFirst(string) {
    return capitalFirstLetter(string);
}

export function toDateString(date) {
    if (Array.isArray(date)) {
        return date.map(toDateString);
    }

    date = toDate(date);
    if (date === null) {
        return null;
    }

    console.log(date, date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());

    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
}


export function secondsToDuration(seconds) {
    if (seconds === null) {
        return '00:00';
    }
    seconds = String(seconds);
    let negative = seconds.startsWith('-');
    seconds = seconds.replace('-', '');
    let hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
    let minutes = String(Math.floor(seconds % 3600 / 60)).padStart(2, '0');

    return (negative ? '- ' : '') + `${hours}:${minutes}`;
}

export function durationToSeconds(duration) {
    if (duration === null) {
        return 0;
    }
    let negative = duration.startsWith('-');
    duration = duration.replace('-', '');
    let [hours, minutes] = duration.split(':');
    let seconds = parseInt(hours) * 3600 + parseInt(minutes) * 60;
    return (negative ? '-' : '') + seconds;
}
