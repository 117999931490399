import {defineStore} from 'pinia'
import {collect} from "collect.js";
import {CategoryDto, ProductDto, QuotationDto, QuotationLineDto, VatRates} from "@/../types/generated";
import {blank} from "@/typeSciptHelpers";
import {toRaw} from "vue";

interface QuotationLineState {
    id: number,
    quotation_lines: Array<QuotationLineDto>
    products: Array<ProductDto>
    categories: Array<CategoryDto>
}

export const useQuotationLineStore = defineStore('quotation_lines', {
    state: (): QuotationLineState => ({
        id: 0,
        quotation_lines: [],
        products: [],
        categories: [],
    }),
    getters: {
        getLines: (state: QuotationLineState): Array<QuotationLineDto> => {
            return toRaw(state.quotation_lines);
        },
        getLinesByCategory: (state: QuotationLineState): [string: Array<QuotationLineDto>] => {
            return collect(state.quotation_lines).whereNotNull('category_dto').groupBy('category_dto.title').sortKeys().all();
        },
        getCategoryTitles: (state: QuotationLineState): Array<string> => {
            return state.categories.map((category: CategoryDto): string | null => category.title).filter((title: string | null): boolean => title !== null) as Array<string>;
        },
        getCategoryFromTitle: (state: QuotationLineState) => (title: string): CategoryDto | null => {
            return state.categories.find((category: CategoryDto): boolean => category.title === title) || null;
        },
        getProductsByCategory: (state: QuotationLineState) => (category: CategoryDto): Array<ProductDto> => {
            return state.products.filter((product: ProductDto) => product.categories.map((category: CategoryDto) => category.id).includes(category.id));
        },
        getProductsByCategoryTitle: (state: QuotationLineState) => (categoryTitle: string): Array<ProductDto> => {
            const category: CategoryDto | null = state.categories.find((category: CategoryDto) => category.title === categoryTitle) || null;

            return category
                ? state.products.filter((product: ProductDto) => product.categories.map((category: CategoryDto) => category.id).includes(category.id))
                : [];
        },
        getProductFromTitle: (state: QuotationLineState) => (title: string): ProductDto | null => {
            return state.products.find((product: ProductDto) => product.title === title) || null;
        }

    },
    actions: {
        load(quotation: QuotationDto, products: Array<ProductDto>, categories: Array<CategoryDto>) {
            this.id = quotation.id;
            this.quotation_lines = quotation.quotation_lines;
            this.products = products;
            this.categories = categories;
        },
        destroy(id: number) {
            this.quotation_lines = this.quotation_lines.filter((line) => line.id !== id);
        },
        calculateAmount(id: number,) {
            const newLine: QuotationLineDto | undefined = this.quotation_lines.find((line) => line.id === id);
            if (newLine) {
                newLine.amount = newLine.quantity * newLine.price.price;
                this.quotation_lines = this.quotation_lines.map((line) => line.id === id ? newLine : line);
            }

        },
        add(productTitle: string, categoryTitle: string) {
            const errors: Record<string, Array<string>> = {};
            if (blank(productTitle)) {
                errors.productTitle = ['Product is verplicht'];
            }
            if (blank(categoryTitle)) {
                errors.categoryTitle = ['Categorie is verplicht'];
            }

            const currentProductNames = this.quotation_lines.map((line) => line.product_dto?.title);
            if (currentProductNames.includes(productTitle)) {
                errors.productTitle = ['Product is al toegevoegd'];
            }

            if (Object.keys(errors).length > 0) {
                console.log('Errors: ', errors);
                return {errors: errors, success: false};
            }

            const product: ProductDto | null = this.getProductFromTitle(productTitle);
            const category: CategoryDto | null = this.getCategoryFromTitle(categoryTitle);

            if (product && category) {
                this.quotation_lines.push({
                    id: Date.now().valueOf(),
                    quotation_id: this.id,
                    product_id: product.id,
                    description: product.title,
                    quantity: 1,
                    price: product.price,
                    amount: product.price.price,
                    product_dto: product,
                    category_dto: category,
                });
            } else if (category) {
                this.quotation_lines.push({
                    id: Date.now().valueOf(),
                    quotation_id: this.id,
                    product_id: null,
                    description: productTitle,
                    quantity: 1,
                    price: {
                        price: 100,
                        vat_rate: VatRates.HIGH,
                        price_includes_vat: true,
                        currency: 'EUR',
                    },
                    amount: 100,
                    product_dto: {
                        title: productTitle,
                    },
                    category_dto: category,
                });
            } else {
                this.quotation_lines.push({
                    id: Date.now().valueOf(),
                    quotation_id: this.id,
                    description: productTitle,
                    quantity: 1,
                    price: {
                        price: 100,
                        vat_rate: VatRates.HIGH,
                        price_includes_vat: true,
                        currency: 'EUR',
                    },
                    amount: 100,
                    product_dto: {
                        title: productTitle,
                    },
                    category_dto: {
                        title: categoryTitle,
                    },
                });
            }
            return {errors: errors, success: true};
        }
    },

})
