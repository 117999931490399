<script setup>
import Button from '@/Components/Buttons/Button.vue';
import Form from '@/Components/Input/Form.vue';
import Modal from '@/Components/Modal/Modal.vue';
import {inject, ref, watch} from 'vue'
import Group from "@/Components/Input/Group.vue";
import {useForm} from "@inertiajs/vue3";

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true,
    },
    selectedTypes: {
        type: Array,
        required: true,
    },
})
const show = ref(props.modelValue);
watch(() => props.modelValue, (value) => {
    show.value = value;
})
watch(show, (value) => {
    emits('update:modelValue', value);
})
const emits = defineEmits(['update:modelValue'])

const route = inject('route');

const form = useForm({
    from_date: null,
    to_date: null,
    selected_types: props.selectedTypes,
});

function submit() {
    form.selectedTypes = props.selectedTypes;
    window.open(route('events.export', form.data()), '_blank');
}

</script>
<template>
    <Modal v-model="show">
        <Form>
            <Group>
                <FormKit
                    v-model="form.from_date"
                    :errors="form.errors.from_date"
                    :label="$t('From date')"
                    type="date"
                />
                <FormKit
                    v-model="form.to_date"
                    :errors="form.errors.to_date"
                    :label="$t('To date')"
                    type="date"
                />
            </Group>
        </Form>

        <template #buttons>
            <Button
                :form="form"
                :label="$t('Print')"
                @click="submit"
            />
        </template>
    </Modal>
</template>
