<script setup>
import Button from '@/Components/Buttons/Button.vue';
import Card from '@/Components/Card/Card.vue';
import Password from '@/Components/Input/Password.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import {mapErrors} from '@/utilities';
import Form from '@/Components/Input/Form.vue';
import Group from '@/Components/Input/Group.vue';
import {Head, Link, useForm} from '@inertiajs/vue3';

import {inject} from 'vue';

const route = inject('route');

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submitForm = () => {
    form.post(route('login'), {
        onFinish: () => form.reset('password'),
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <GuestLayout>
        <Head title="Log in" />
        <Card narrow>
            <div
                v-if="status"
                class="mb-4 text-sm font-medium text-green-600"
            >
                {{ status }}
            </div>

            <form @submit.prevent="submit">
                <div>
                    <Form>
                        <Group>
                            <FormKit
                                v-model="form.email"
                                :errors="form.errors?.email"
                                :value="form.email"
                                label="Email"
                                type="email"
                            />
                        </Group>
                        <Group>
                            <Password
                                v-model="form.password"
                                :errors="form.errors?.password"
                                :value="form.password"
                                label="Wachtwoord"
                            />
                        </Group>
                        <Group>
                            <FormKit
                                v-model="form.remember"
                                :errors="form.errors?.remember"
                                :value="form.remember"
                                label="Onthoud mij"
                                type="checkbox"
                            />
                        </Group>
                    </Form>

                    <div class="mt-4 flex items-center justify-between gap-4">
                        <Link
                            :href="route('password.request')"
                            class="rounded-md text-sm text-gray-600 underline hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            Wachtwoord vergeten?
                        </Link>

                        <Button
                            :form="form"
                            label="Inloggen"
                            @click="submitForm"
                        />
                    </div>
                </div>
            </form>
        </Card>
    </GuestLayout>
</template>
