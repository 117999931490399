<script setup>
import {useForm} from "@inertiajs/vue3";
import {
    ArrowUpCircleIcon,
    ArrowDownCircleIcon,
} from '@heroicons/vue/24/outline';
import ReactiveSlot from "@/Components/Buttons/ReactiveSlot.vue";
import {inject} from "vue";


const route = inject('route');

const props = defineProps({
    model_id: [String, Number],
    model_type: String,
});

const formUp = useForm({
    model_id: props.model_id,
    model_type: props.model_type,
});

const formDown = useForm({
    model_id: props.model_id,
    model_type: props.model_type,
});

function submitFormUp() {

    formUp.post(route('sortables.up'), {
        preserveState: true,
        preserveScroll: true,
    });

}

function submitFormDown() {
    formDown.post(route('sortables.down'), {
        preserveState: true,
        preserveScroll: true,
    });
}
</script>
<template>
    <div class="flex items-center gap-3">
        <button
            class="flex-center relative size-5"
            type="button"
            @click="submitFormUp()"
        >
            <ReactiveSlot
                :processing="formUp?.processing ?? false"
            >
                <ArrowUpCircleIcon class="size-5" />
            </ReactiveSlot>
        </button>
        <button
            class="flex-center relative size-5"
            type="button"
            @click="submitFormDown()"
        >
            <ReactiveSlot
                :processing="formDown?.processing ?? false"
            >
                <ArrowDownCircleIcon class="size-5" />
            </ReactiveSlot>
        </button>
    </div>
</template>
