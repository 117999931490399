<script setup>
import {useForm} from '@inertiajs/vue3';
import {inject} from 'vue';
import Button from '@/Components/Buttons/Button.vue';
import Card from '@/Components/Card/Card.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import Form from '@/Pages/Quotations/Partials/Form.vue';
import {useUser} from '@/stores/userStore';
import {mapErrors} from '@/utilities';

const route = inject('route');
const user = useUser();

const props = defineProps({
    query: Object,
    contactOptions: Object,
    templateOptions: Object,
    typeOptions: Object,
    productOptions: Object,
});

const form = useForm({
    status: 'concept',
    template: Object.keys(props.templateOptions)[0],
    type: Object.keys(props.typeOptions)[0],
    number: '',
    date: '',
    assembly_date: '',
    disassembly_date: '',
    delivery_date: '',
    contact_id: '',
    start: '',
    end: '',
    adults: '',
    children: '',
    postcode: '',
    street_number: '',
    street_number_addition: '',
    street_name: '',
    city: '',
    country: '',
    longitude: '',
    latitude: '',
    ...props.query,
});

const submitForm = () => {
    form.post(route('quotations.store'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <AppLayout>
        <Card>
            <template #title>
                Offerte aanmaken
            </template>
            <FormKit
                id="signup-form"
                :actions="false"
                type="form"
                @submit="submitForm"
            >
                <div class="grid grid-cols-1 gap-2">
                    <Form
                        :contact-options="contactOptions"
                        :form="form"
                        :template-options="templateOptions"
                        :type-options="typeOptions"
                    />
                </div>
            </FormKit>


            <template #buttons>
                <Button
                    v-if="user.can('create-quotations')"
                    :form="form"
                    label="Opslaan"
                    @click="submitForm"
                />
            </template>
        </Card>
    </AppLayout>
</template>
