<script setup>
import Button from '@/Components/Buttons/Button.vue';
import Card from '@/Components/Card/Card.vue';
import Password from '@/Components/Input/Password.vue';
import {mapErrors} from '@/utilities';
import {useForm} from '@inertiajs/vue3';
import {inject} from 'vue';

const route = inject('route');

const form = useForm({
    current_password: '',
    password: '',
    password_confirmation: '',
});

const updatePassword = () => {
    form.put(route('password.update'), {
        preserveScroll: true,
        onSuccess: () => form.reset(),
        onError: (errors) => {
            form.errors = mapErrors(errors);
            if (form.errors.password) {
                form.reset('password', 'password_confirmation');
            }
            if (form.errors.current_password) {
                form.reset('current_password');
            }
        },
    });
};
</script>

<template>
    <Card>
        <Password
            v-model="form.current_password"
            :errors="form.errors?.current_password"
            label="Huidige wachtwoord"
        />

        <Password
            v-model="form.password"
            :errors="form.errors?.password"
            label="Nieuw wachtwoord"
        />

        <Password
            v-model="form.password_confirmation"
            :errors="form.errors?.password_confirmation"
            label="Herhaling wachtwoord"
        />

        <template #buttons>
            <Button
                :form="form"
                label="Aanpassen"
                @click="updatePassword"
            />
        </template>
    </Card>
</template>
