<script setup>
import Button from '@/Components/Buttons/Button.vue';
import Card from '@/Components/Card/Card.vue';
import Password from '@/Components/Input/Password.vue';
import SelectMultiple from '@/Components/Input/SelectMultiple.vue';
import InputGroup from '@/Components/Input/Group.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import AddressForm from '@/Pages/Events/Partials/AddressForm.vue';
import {mapErrors} from '@/utilities';
import {Head, useForm} from '@inertiajs/vue3';
import {inject} from 'vue';

const route = inject('route');

const props = defineProps({
    query: Object,
    drivingLicenseOptions: Object,
});

const form = useForm({
    first_name: '',
    last_name: '',
    email: '',
    driving_licenses: [],
    password: '',
    password_confirmation: '',
    terms: false,

    phone_number: '',
    postcode: '',
    street_number: '',
    street_number_addition: '',
    street_name: '',
    city: '',
    country: '',
    longitude: '',
    latitude: '',
    ...props.query,
});

const submit = () => {
    form.post(route('register'), {
        onFinish: () => form.reset('password', 'password_confirmation'),
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <GuestLayout>
        <Head title="Register" />
        <Card>
            <InputGroup>
                <FormKit
                    v-model="form.first_name"
                    :errors="form.errors?.first_name"
                    :value="form.first_name"
                    label="Voornaam"
                    type="text"
                    validate="required"
                />
                <FormKit
                    v-model="form.last_name"
                    :errors="form.errors?.last_name"
                    :value="form.last_name"
                    label="Achternaam"
                    type="text"
                />
            </InputGroup>
            <InputGroup>
                <Password
                    v-model="form.password"
                    :errors="form.errors?.password"
                    :value="form.password"
                    label="Wachtwoord"
                />

                <Password
                    v-model="form.password_confirmation"
                    :errors="form.errors?.password_confirmation"
                    :value="form.password_confirmation"
                    label="Wachtwoord bevestigen"
                />
            </InputGroup>
            <InputGroup>
                <FormKit
                    v-model="form.email"
                    :errors="form.errors?.email"
                    :value="form.email"
                    label="Email"
                    type="email"
                />

                <FormKit
                    v-model="form.phone_number"
                    :errors="form.errors?.phone_number"
                    :value="form.phone_number"
                    label="Telefoon"
                    type="text"
                />
            </InputGroup>

            <SelectMultiple
                v-model="form.driving_licenses"
                :errors="form.errors?.driving_licenses"
                :options="drivingLicenseOptions"
                label="Rijbewijzen"
                placeholder="Geen rijbewijs"
            />

            <AddressForm :form="form" />

            <template #buttons>
                <Button
                    :form="form"
                    :label="$t('Register')"
                    :url="route('register')"
                    @click="submit"
                />
            </template>
        </Card>
    </GuestLayout>
</template>
