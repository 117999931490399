import {defineStore} from 'pinia';

export const useUser = defineStore('user', {
    state: () => ({
        // Not really needed but now they show up in the devtools
        loaded: false,
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        impersonating: null,
        abilities: [],
    }),
    actions: {
        load(user) {
            Object.keys(user).forEach((key) => {
                this[key] = user[key];
            });

            this.loaded = true;
        },
        can(permission) {
            if (this.abilities.includes(permission)) {
                return true;
            }
            return false;
        },
        canAny(permissions) {
            return Array.isArray(permissions)
                ? permissions.some((permission) => this.can(permission))
                : this.can(permissions);
        },
        canAll(permissions) {
            return Array.isArray(permissions)
                ? permissions.every((permission) => this.can(permission))
                : this.can(permissions);
        }
    },
});
