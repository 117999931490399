<script setup>
import FormButton from '@/Components/Buttons/FormButton.vue';
import Group from '@/Components/Input/Group.vue';
import Title from '@/Components/Page/Title.vue';
import Table from '@/Components/Table/Table.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import {useUser} from '@/stores/userStore';
import {useForm, usePage} from '@inertiajs/vue3';
import {computed, inject} from 'vue';
import {useDebounceFn} from "@vueuse/shared";
import SelectSearch from "@/Components/Input/SelectSearch.vue";

const route = inject('route');
const user = useUser();

const props = defineProps({
    query: Object,
    leaveRegistrations: Object,
    statusOptions: Object,
    userOptions: Object,
});

const form = useForm({
    search: props.query.search ?? '',
    orderBy: props.query.orderBy ?? 'name',
    orderDir: props.query.orderDir ?? 'desc',
    page: props.query.page ?? 1,
    user_id: props.query.user_id ?? '',
    status: props.query.status ?? '',
});

const getData = (page = 1) => {
    form.page = page;

    form.get(route('leave-registrations.index'), {
        preserveState: true,
        only: ['leaveRegistrations', 'query'],
    });
};

const debouncedGetData = useDebounceFn(() => getData(), 300);

const tableSettings = computed(() => [
    {label: 'Naam'},
    {label: 'Status'},
    {label: "Start datum", cellType: 'date', orderBy: "start_date"},
    {label: 'Einde datum', cellType: 'date',},
    {label: 'Aantal uur'},
    {
        width: '1%',
        cellType: 'arrow',
        permission: user.canAny(['update-leave-registrations']),
    },
]);

const tableData = computed(() =>
    props.leaveRegistrations.data.map((registration) => {
        return {
            id: registration.id,
            rowData: [
                registration.employee.name,
                props.statusOptions[registration.status],
                registration.start_date,
                registration.end_date,
                registration.total_hours,
                route('leave-registrations.edit', {
                    leave_registration: registration.id,
                    intended: route(usePage().props.route.current_name, form.data())
                }),
            ],
        };
    })
);


</script>

<template>
    <AppLayout>
        <Title :title="$t('Leave registrations')">
            <FormButton
                v-if="user.canAny(['create-leave-registrations'])"
                :label="$t('Leave registrations')"
                :url="route('leave-registrations.create')"
                method="get"
            />
        </Title>
        <Table
            v-model:orderBy="form.orderBy"
            v-model:orderDir="form.orderDir"
            :data="leaveRegistrations"
            :loading="form.processing"
            :table-data="tableData"
            :table-settings="tableSettings"
            @update:order-by="getData()"
            @update:order-dir="getData()"
            @set-page="getData"
        >
            <template #filters>
                <Group>
                    <FormKit
                        v-model="form.search"
                        :debounce="300"
                        label="Zoeken"
                        @update:model-value="debouncedGetData()"
                    />
                    <SelectSearch
                        v-model="form.user_id"
                        :options="userOptions"
                        clearable
                        first-selected
                        label="Werknemer"
                        placeholder="Selecteer een werknemer"
                        @update:model-value="getData()"
                    />
                </Group>
            </template>
        </Table>
    </AppLayout>
</template>
