<script setup>
import Button from '@/Components/Buttons/Button.vue';
import Form from '@/Components/Input/Form.vue';
import Modal from '@/Components/Modal/Modal.vue';
import {inject, ref, watch} from 'vue'
import {useForm} from "@inertiajs/vue3";


const props = defineProps({
    modelValue: Boolean,
    exportTypeOptions: Object,
});
const show = ref(props.modelValue);
watch(() => props.modelValue, (value) => {
    show.value = value;
})
watch(show, (value) => {
    emits('update:modelValue', value);
})
const emits = defineEmits(['update:modelValue'])

const route = inject('route');

const form = useForm({
    start: '',
    end: '',
    type: Object.keys(props.exportTypeOptions)[0],
});

const submit = () => {
    if (form.start === '') {
        form.errors = {
            start: ['Dit veld is verplicht.'],
        };
        return;
    }
    window.open(route('time-registrations.export', {start: form.start, end: form.end, type: form.type}), '_blank');

    close();
};


</script>
<template>
    <Modal
        v-model="show"
        title="Uren exporteren"
    >
        <Form>
            <FormKit
                v-model="form.start"
                :errors="form?.errors.start"
                :value="form.start"
                label="Van"
                name="start"
                type="date"
            />
            <FormKit
                v-model="form.end"
                :errors="form?.errors.end"
                :value="form.end"
                label="Tot"
                name="start"
                type="date"
            />
            <FormKit
                v-model="form.type"
                :errors="form?.errors.type"
                :options="exportTypeOptions"
                :value="form.type"
                label="Type"
                name="type"
                type="select"
            />
        </Form>

        <template #buttons>
            <Button
                :form="form"
                :label="$t('Export')"
                @click="submit"
            />
        </template>
    </Modal>
</template>
