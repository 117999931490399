<script setup>
import {Link} from '@inertiajs/vue3';
import FormButton from '@/Components/Buttons/FormButton.vue';
import mail from '@i/mail.svg';
import phone from '@i/phone.svg';
import Arrow from '@/Components/Table/Arrow.vue';
import {inject} from "vue";
import {ucFirst, secondsToDuration} from "@/utilities.js";
import Order from "@/Components/Table/Cells/Order.vue";

const dayjs = inject('dayjs');

defineProps({
    cellData: [Object, String, Number],
    tableSettings: Object,
    isLast: Boolean,
    from: String,
    till: String,
});
</script>

<template>
    <div
        :class="[
            {
                'md:hidden': till === 'md',
                'lg:hidden': till === 'lg',
                'xl:hidden': till === 'xl',
                '2xl:hidden': till === '2xl',
                'hidden md:block': from === 'md',
                'hidden lg:block': from === 'lg',
                'hidden xl:block': from === 'xl',
                'hidden 2xl:block': from === '2xl',
            },
            ,
        ]"
    >
        <template v-if="typeof tableSettings.cellType === 'undefined'">
            <template v-if="Array.isArray(cellData)">
                <div
                    v-for="item in cellData"
                    :key="item"
                    class="w-full leading-tight"
                >
                    {{ item }}
                </div>
            </template>
            <template v-else>
                <div class="w-full leading-tight">
                    {{ cellData }}
                </div>
            </template>
        </template>
        <template v-else-if=" tableSettings.cellType === 'duration'">
            <div class="w-full leading-tight">
                {{ secondsToDuration(cellData) }}
            </div>
        </template>
        <template v-else-if="tableSettings.cellType === 'show'">
            <Link
                :href="cellData[1]"
                class="group relative ml-auto font-medium text-pine-600 hover:text-pine-900"
            >
                {{ cellData[0] }}
                <div
                    class="absolute inset-x-0 bottom-px border-b border-pine-500 opacity-0 transition-opacity group-hover:opacity-100"
                />
            </Link>
        </template>
        <template v-else-if="tableSettings.cellType === 'money'">
            <div class="w-full leading-tight">
                € {{ (parseInt(cellData) / 100).toFixed(2).replace('.', ',') }}
            </div>
        </template>
        <template v-else-if="tableSettings.cellType === 'arrow'">
            <Arrow :href="cellData" />
        </template>
        <template v-else-if="tableSettings.cellType === 'order'">
            <Order v-bind="cellData" />
        </template>
        <template v-else-if="tableSettings.cellType === 'date'">
            <div class="w-full leading-tight">
                {{ ucFirst(dayjs(cellData).format('dddd, DD-MM-YYYY')) }}
            </div>
        </template>
        <template v-else-if="tableSettings.cellType === 'email'">
            <template v-if="Array.isArray(cellData)">
                <a
                    v-for="item in cellData"
                    :key="item"
                    :href="`mailto:${item}`"
                    class="ml-auto text-pine-600 hover:text-pine-900"
                >
                    <div class="group relative hidden md:block">
                        {{ item }}
                        <div
                            class="absolute inset-x-0 bottom-px border-b border-pine-500 opacity-0 transition-opacity group-hover:opacity-100"
                        />
                    </div>

                    <div class="flex size-7 items-center justify-center rounded-full bg-pine-500 md:hidden">
                        <img
                            :src="mail"
                            alt="mail"
                            class="h-auto w-3"
                        >
                    </div>
                </a>
            </template>
            <template v-else>
                <a
                    :href="`mailto:${cellData}`"
                    class="ml-auto text-pine-600 hover:text-pine-900"
                >
                    <div class="group relative hidden md:block">
                        {{ cellData }}
                        <div
                            class="absolute inset-x-0 bottom-px border-b border-pine-500 opacity-0 transition-opacity group-hover:opacity-100"
                        />
                    </div>

                    <div class="flex size-7 items-center justify-center rounded-full bg-pine-500 md:hidden">
                        <img
                            :src="mail"
                            alt="mail"
                            class="h-auto w-3"
                        >
                    </div>
                </a>
            </template>
        </template>
        <template v-else-if="tableSettings.cellType === 'phone'">
            <div class="flex justify-start">
                <a
                    :href="`tel:${cellData}`"
                    class="group relative shrink text-pine-600 hover:text-pine-900"
                >
                    <div class="group relative hidden md:block">
                        {{ cellData }}
                        <div
                            class="absolute inset-x-0 bottom-px border-b border-pine-500 opacity-0 transition-opacity group-hover:opacity-100"
                        />
                    </div>

                    <div class="flex size-7 items-center justify-center rounded-full bg-pine-500 md:hidden">
                        <img
                            :src="phone"
                            alt="phone"
                            class="h-3 w-auto"
                        >
                    </div>
                </a>
            </div>
        </template>
        <template v-else-if="tableSettings.cellType === 'formButton'">
            <div
                :class="{
                    'justify-end': isLast,
                    'justify-start': !isLast,
                }"
                class="flex w-full gap-2 md:flex-col md:gap-4"
            >
                <FormButton
                    v-for="button in cellData"
                    :key="button.label"
                    :data="button.data"
                    :disabled="button.disabled"
                    :icon="button.icon"
                    :label="button.label"
                    :method="button.method"
                    :red="button.red"
                    :url="button.url"
                    inline
                />
            </div>
        </template>
        <template v-else>
            <div class="flex min-h-10 items-center px-4 py-2 font-bold text-red-600">
                {{ tableSettings.cellType }} is unknown
            </div>
        </template>
    </div>
</template>
