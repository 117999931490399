<script setup>
import SmallButton from '@/Components/Buttons/SmallButton.vue';
import Divider from '@/Components/Divider.vue';
import DividerButton from '@/Components/DividerButton.vue';
import DividerHorizontal from '@/Components/DividerHorizontal.vue';
import Label from '@/Components/Input/Label.vue';
import SelectSearch from '@/Components/Input/SelectSearch.vue';
import InputGroup from '@/Components/Input/Group.vue';
import {TrashIcon, UserPlusIcon} from '@heroicons/vue/24/outline';
import {router} from '@inertiajs/vue3';
import {inject} from 'vue';
import AddressForm from '@/Pages/Events/Partials/AddressForm.vue';
import ReminderForm from '@/Pages/Events/Partials/Forms/ReminderForm.vue';
import TimeInput from "@/Components/Input/TimeInput.vue";

const dayjs = inject('dayjs');
const route = inject('route');

const props = defineProps({
    form: Object,
    create: Boolean,
    typeOptions: Object,
    contactOptions: Object,
    quotationOptions: Object,
    ownerOptions: Object,
});

function addChild() {
    props.form.children.push({
        title: '',
        description: '',
        start: dayjs().format('YYYY-MM-DD'),
        end: '',
        all_day: true,
        capacity: '',
    });
}

const fillAddress = () => {
    if (props.form.contact_id === '') {
        return;
    }
    window.axios
        .get(
            route('api.address.index', {
                contact_id: props.form.contact_id,
            })
        )
        .then(function (response) {
            props.form.errors.contact_id = [];
            if (response.data.data.length === 0) {
                props.form.postcode = '';
                props.form.street_number = '';
                props.form.street_name = '';
                props.form.city = '';
                props.form.country = '';
                props.form.longitude = '';
                props.form.latitude = '';
                props.form.errors.contact_id = ['Geen adres bekent'];
                return;
            }
            let address = response.data.data[0];
            props.form.postcode = address.postcode;
            props.form.street_number = address.street_number;
            props.form.street_name = address.street_name;
            props.form.city = address.city;
            props.form.country = 'NL';
            props.form.longitude = address.longitude;
            props.form.latitude = address.latitude;
            props.form.errors.postcode = [];
        });
};

const helperLine = `<span class="underline cursor-pointer">Adres overnemen</span>`;
</script>
<template>
    <div class="flex w-full gap-4">
        <SelectSearch
            v-model="form.contact_id"
            :errors="form.errors?.contact_id"
            :help="helperLine"
            :options="contactOptions"
            label="Klant"
            @help-clicked="fillAddress"
        />

        <div class="flex flex-col">
            <Label label="&nbsp;" />
            <SmallButton
                @click="
                    router.get(
                        route('contacts.create', {
                            intended: route('events.create', { ...form.data() ,contact_id: '{created_id}' }),
                        })
                    )
                "
            >
                <UserPlusIcon
                    aria-hidden="true"
                    class="size-5 text-beige-500"
                />
            </SmallButton>
        </div>
    </div>

    <SelectSearch
        v-model="form.quotation_id"
        :errors="form.errors?.quotation_id"
        :options="quotationOptions"
        label="Offerte"
    />

    <AddressForm :form="form" />

    <ReminderForm
        :create="create"
        :form="form"
        :owner-options="ownerOptions"
        :type-options="typeOptions"
    />

    <FormKit
        v-model="form.capacity"
        :errors="form.errors?.capacity"
        :value="form.capacity"
        label="Aantal werknemer"
        type="number"
    />
    <InputGroup>
        <FormKit
            v-model="form.open_attendance_from"
            :errors="form.errors?.open_attendance_from"
            :value="form.open_attendance_from"
            label="Algemene inschrijving openzetten"
            type="text"
        />

        <FormKit
            v-model="form.retract_attendance_till"
            :errors="form.errors?.retract_attendance_till"
            :value="form.retract_attendance_till"
            label="Afmelden tot"
            type="text"
        />
    </InputGroup>

    <Divider title=" Dagdelen" />
    <template
        v-for="(child, i) in form.children"
        :key="i"
    >
        <div class="flex">
            <div class="flex-1">
                <!-- {{ form.errors?.children[0].title }} -->
                <FormKit
                    v-model="form.children[i].title"
                    :errors="(form.errors?.children ?? [])[i]?.title"
                    :value="form.children[i].title"
                    label="Titel"
                    type="text"
                />
                <FormKit
                    v-model="form.children[i].description"
                    :errors="(form.errors?.children ?? [])[i]?.description"
                    :value="form.children[i].description"
                    label="Omschrijving"
                    rows="4"
                    type="textarea"
                />

                <FormKit
                    v-model="form.children[i].capacity"
                    :errors="(form.errors?.children ?? [])[i]?.capacity"
                    :value="form.children[i].capacity"
                    label="Aantal werknemer"
                    type="number"
                />
                <FormKit
                    v-model="form.children[i].all_day"
                    :errors="(form.errors?.children ?? [])[i]?.all_day"
                    :value="form.children[i].all_day"
                    label="Hele dag"
                    type="checkbox"
                />

                <template v-if="form.children[i].all_day">
                    <TimeInput
                        v-model="form.children[i].start"
                        :errors="(form.errors?.children ?? [])[i]?.start"
                        label="Start"
                    />
                </template>

                <template v-else>
                    <InputGroup>
                        <TimeInput
                            v-model="form.children[i].start"
                            :errors="(form.errors?.children ?? [])[i]?.start"
                            label="Start"
                        />
                        <TimeInput
                            v-model="form.children[i].end"
                            :errors="(form.errors?.children ?? [])[i]?.end"
                            label="Eind"
                        />
                    </InputGroup>
                </template>
            </div>
            <div class="relative w-10 py-2">
                <!-- -->
                <DividerHorizontal>
                    <TrashIcon @click="form.children.splice(i, 1)" />
                </DividerHorizontal>
            </div>
        </div>
    </template>

    <DividerButton
        title="Deel toevoegen"
        @button-clicked="addChild()"
    />
</template>
