<script setup>
import {useSlots} from 'vue';
import Body from '@/Components/Table/Body.vue';
import Header from '@/Components/Table/Header.vue';

defineProps({
    tableSettings: Array,
    tableData: Array,
    orderBy: String,
    orderDir: String,
    loading: Boolean,
    title: String,
    subtitle: String,
    classes: {
        type: Object,
        default: () => ({
            title: '',
            subtitle: '',
        }),
    },
});

const emits = defineEmits(['update:orderBy', 'update:orderDir', 'buttonClicked', 'rowClicked', 'cellClicked']);

const orderByUpdated = (newOrderBy) => emits('update:orderBy', newOrderBy);
const orderDirUpdated = (newOrderDir) => emits('update:orderDir', newOrderDir);


const slots = useSlots();
</script>

<template>
    <div>
        <slot name="filters" />
        <div class="mt-4 shadow ring-1  ring-black/5 sm:rounded-lg ">
            <div class=" hide-scrollbar overflow-y-hidden overflow-x-scroll sm:rounded-lg ">
                <div
                    v-if="title || slots.top"
                    class="flex border-b border-gray-200 bg-white px-4 py-5 sm:px-6"
                >
                    <div class="flex flex-1 flex-col justify-center gap-x-4">
                        <h2
                            v-if="title"
                            :class="classes.title"
                            class="text-lg font-medium leading-6 text-gray-900"
                        >
                            {{ title }}
                        </h2>

                        <p
                            v-if="subtitle"
                            :class="classes.subtitle"
                            class="mt-1 max-w-2xl text-sm text-gray-500"
                        >
                            {{ subtitle }}
                        </p>
                    </div>
                    <div
                        v-if="slots.top"
                        class="flex flex-1 justify-end gap-4"
                    >
                        <slot name="top" />
                    </div>
                </div>
                <table class="min-w-full divide-y divide-gray-300 ">
                    <Header
                        :order-by="orderBy"
                        :order-dir="orderDir"
                        :table-settings="tableSettings"
                        @update:order-by="orderByUpdated"
                        @update:order-dir="orderDirUpdated"
                    />
                    <Body
                        :class="{ 'opacity-30': loading }"
                        :loading="loading"
                        :table-data="tableData"
                        :table-settings="tableSettings"
                        class="transition-opacity"
                    />
                </table>

                <div class="flex items-center justify-end gap-x-6 border-t p-4 sm:px-6">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>
