<script setup>
import {mapErrors} from '@/utilities';
import {useForm} from '@inertiajs/vue3';
import {inject} from 'vue';

import Button from '@/Components/Buttons/Button.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import Form from '@/Pages/Attendance/Partials/Form.vue';

import Card from '@/Components/Card/Card.vue';
import {useUser} from '@/stores/userStore';

const route = inject('route');
const dayjs = inject('dayjs');
const user = useUser();

const props = defineProps({
    attendance: Object,
    areaOptions: Array,
    statusOptions: Object,
    roleOptions: Array,
});

const form = useForm({
    area: '',
    role: '',
    ...props.attendance,
    start: dayjs(props.attendance.start ?? props.attendance.event.start).format('HH:mm'),
    end: dayjs(props.attendance.end ?? props.attendance.event.end).format('HH:mm'),
});

const submitForm = () => {
    form.put(route('attendances.update', props.attendance.id), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <AppLayout>
        <div class="h-4" />
        <Card>
            <template #title>
                Aanwezigheid aanpassen
            </template>

            <div class="grid grid-cols-1 gap-2">
                <Form
                    :area-options="areaOptions"
                    :form="form"
                    :role-options="roleOptions"
                    :status-options="statusOptions"
                />
            </div>

            <template #buttons>
                <Button
                    v-if="user.can('update-users')"
                    :form="form"
                    label="Aanpassen"
                    @click="submitForm"
                />

                <!-- <ConformationButton
                    v-if="user.can('delete-users')"
                    label="Verwijderen"
                    conformation-title="Gebruiker verwijderen"
                    conformation-message="Weet je zeker dat je deze gebruiker wilt verwijderen?"
                    method="delete"
                    :url="route('users.destroy', user.id)"
                /> -->
            </template>
        </Card>
    </AppLayout>
</template>
