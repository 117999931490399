<script lang="ts" setup>


import SmallButton from "@/Components/Buttons/SmallButton.vue";
import Divider from "@/Components/Divider.vue";
import AutoCompleet from "@/Components/Input/AutoCompleet.vue";
import Form from "@/Components/Input/Form.vue";
import Group from "@/Components/Input/Group.vue";
import Label from "@/Components/Input/Label.vue";
import { useQuotationLineStore } from "@/stores/quotationLinesStore";
import { PlusIcon } from "@heroicons/vue/24/outline";
import { ref } from "vue";

const store = useQuotationLineStore();

const categoryTitle = ref('');
const productTitle = ref('');
const errors = ref({});

function submit() {
    errors.value = store.add(productTitle.value, categoryTitle.value).errors;
}
</script>

<template>
    <Divider class="-mx-4 sm:-mx-8" />
    <Form>
        <Group>
            <AutoCompleet
                v-model="categoryTitle"
                :errors="errors.categoryTitle"
                :options="store.getCategoryTitles"
                label="Category"
            />

            <div class="flex gap-4">
                <AutoCompleet
                    v-model="productTitle"
                    :errors="errors.productTitle"
                    :options="store.getProductsByCategoryTitle(categoryTitle).map(product => product.title)"
                    label="Producten"
                />
                <div class="mb-2 flex flex-col">
                    <Label label="&nbsp;" />
                    <SmallButton @click="submit()">
                        <PlusIcon
                            aria-hidden="true"
                            class="size-5 text-white"
                        />
                    </SmallButton>
                </div>
            </div>
        </Group>
    </Form>
</template>
