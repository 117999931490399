<script setup>
import FormButton from '@/Components/Buttons/FormButton.vue';
import InputGroup from '@/Components/Input/Group.vue';
import PageTitle from '@/Components/Page/Title.vue';
import Pagination from '@/Components/Pagination.vue';
import Select from '@/Components/Select.vue';
import Table from '@/Components/Table/Table.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import {useUser} from '@/stores/userStore';
import {capitalFirstLetter} from '@/utilities';
import {computed, inject} from 'vue';
import {useIndex} from "@/Composables/useIndex.ts";

const route = inject('route');
const user = useUser();
const dayjs = inject('dayjs');

const props = defineProps({
    query: Object,
    timeRegistrations: Object,
    statusOptions: Object,
    typeOptions: Object,
});

const {form, getData} = useIndex('timeRegistrations', ['type', 'status']);

const tableSettings = computed(() => [
    {label: 'Start', orderBy: 'start', width: '10%'},
    {label: 'Einde', width: '10%'},
    {label: 'Pauze', width: '10%'},
    {label: 'Status', width: '10%'},
    {label: 'Acties', width: '1%', cellType: 'arrow'},
]);

const tableData = computed(() =>
    props.timeRegistrations.data.map((registration) => {
        return {
            id: registration.id,
            rowData: [
                capitalFirstLetter(dayjs(registration.start).format('dddd, DD-MM-YYYY HH:mm')),
                capitalFirstLetter(dayjs(registration.end).format('dddd, DD-MM-YYYY HH:mm')),
                registration.break,
                props.statusOptions[registration.status],
                route('own-time-registrations.edit', registration.id),
            ],
        };
    })
);


</script>

<template>
    <AppLayout>
        <PageTitle title="Uren registratie">
            <FormButton
                v-if="user.canAny(['create-own-time-registrations'])"
                :url="route('own-time-registrations.create')"
                label="Uren toevoegen"
                method="get"
            />
        </PageTitle>
        <Table
            v-model:orderBy="form.orderBy"
            v-model:orderDir="form.orderDir"
            :loading="form.processing"
            :table-data="tableData"
            :table-settings="tableSettings"
            @update:order-by="getData()"
            @update:order-dir="getData()"
        >
            <template #filters>
                <InputGroup>
                    <Select
                        v-model="form.status"
                        :options="statusOptions"
                        clearable
                        label="Status"
                        placeholder="Selecteer een status"
                        @change="getData()"
                    />
                    <Select
                        v-model="form.type"
                        :options="typeOptions"
                        clearable
                        label="Type"
                        placeholder="Selecteer een type"
                        @change="getData()"
                    />
                </InputGroup>
            </template>
            <Pagination
                :page-data="timeRegistrations"
                @set-page="getData"
            />
        </Table>
    </AppLayout>
</template>
