export enum AdditionalInformation {
    'LOCAL_PHOTOGRAPHERS_FLYER' = 'local_photographers_flyer',
    'LOCAL_FLORISTS_FLYER' = 'local_florists_flyer',
    'LOCAL_MUSICIANS_FLYER' = 'local_musicians_flyer',
    'LOCAL_CAKE_MAKERS_FLYER' = 'local_cake_makers_flyer'
}
export type CategoryDto = {
    id?: number | null;
    title?: string | null;
    quotation_description?: string | null;
    category_id?: number | null;
    vat_rate?: VatRates | null;
    order?: number | null;
};

export enum DrivingLicense {
    'AM' = 'am',
    'A' = 'a',
    'A1' = 'a1',
    'A2' = 'a2',
    'B' = 'b',
    'BE' = 'be',
    'B_PLUS' = 'b+',
    'C' = 'c',
    'CE' = 'ce',
    'C1' = 'c1',
    'C1E' = 'c1e',
    'D' = 'd',
    'DE' = 'de',
    'D1' = 'd1',
    'D1E' = 'd1e',
    'T' = 't'
}

export enum EventLogEvent { 'SEND_GENERAL_ATTENDANCE_NOTIFICATION' = 'send_general_attendance_notification' }

export enum EventType {
    'AGENDA_ITEM' = 'agenda_item',
    'EVENT' = 'event',
    'GO_AHEAD_EAGLES' = 'go_ahead_eagles',
    'PERSONAL_APPOINTMENT' = 'personal_appointment'
}

export enum ExportType { 'PAYROLL' = 'payroll', 'OTHERS' = 'others' }

export enum LogEvent {
    'APPLIED' = 'applied',
    'INVITED' = 'invited',
    'ACCEPTED' = 'accepted',
    'REJECTED' = 'rejected',
    'retracted' = 'retracted',
    'SENT_REMINDER' = 'sent_reminder'
}

export enum LogEvent {
    'LOGGED_IN' = 'logged_in',
    'FAVORED' = 'favored',
    'UNFAVORED' = 'unfavored',
    'APPROVED' = 'approved',
    'UNAPPROVED' = 'unapproved'
}

export enum LogEvent { 'SENT' = 'sent', 'ACCEPTED' = 'accepted', 'PLANNED' = 'planned', 'REVISED' = 'revised' }
export type Price = {
    price_including_vat?: number;
    price_excluding_vat?: number;
    vat?: number;
    price?: number;
    vat_rate?: VatRates;
    price_includes_vat?: boolean;
    currency?: string;
};
export type ProductDto = {
    id?: number;
    title?: string;
    subtitle?: string | null;
    price?: Price | null;
    price_variant?: Price | null;
    categories?: Array<any>;
};
export type QuotationDto = {
    id?: number;
    status?: Status;
    type?: Type;
    template?: Template;
    show_total?: boolean;
    include_vat?: boolean;
    number?: string;
    date?: string;
    due_date?: string;
    contact_id?: number;
    event_id?: number;
    start?: string;
    end?: string;
    disassembly_date?: string;
    assembly_date?: string;
    delivery_date?: string;
    adults?: number;
    children?: number;
    description?: string;
    decoration?: string;
    token?: string;
    intro?: string;
    outro?: string;
    email?: string;
    quotation_lines?: any | Array<any>;
};
export type QuotationLineDto = {
    id?: number;
    quotation_id?: number;
    product_id?: number;
    status?: Status;
    description?: string;
    quantity?: number;
    price?: Price;
    amount?: number;
    product_dto?: ProductDto;
    category_dto?: CategoryDto;
};

export enum RepeatUnit { 'DAYS' = 'days', 'WEEKS' = 'weeks', 'MONTHS' = 'months', 'YEARS' = 'years' }

export enum RoleName {
    'ADMIN' = 'admin',
    'BACKOFFICE' = 'backoffice',
    'LOGISTICS' = 'logistics',
    'COOK' = 'cook',
    'REGULAR_STAFF' = 'regular staff',
    'PAYROLL' = 'payroll'
}

export enum Status { 'CREATED' = 'created', 'PACKED' = 'packed' }

export enum Status {
    'APPLIED' = 'applied',
    'INVITED' = 'invited',
    'ACCEPTED' = 'accepted',
    'REJECTED' = 'rejected',
    'RETRACTED' = 'retracted'
}

export enum Status { 'Full' = 'full', 'Almost_full' = 'almost_full' }

export enum Status {
    'CONCEPT' = 'concept',
    'SENT' = 'sent',
    'ACCEPTED' = 'accepted',
    'DECLINED' = 'declined',
    'PLANNED' = 'planned',
    'REVISED' = 'revised'
}

export enum Template { 'STANDARD' = 'standard' }

export enum TimeRegistrationLogEvent {
    'SUBMITTED' = 'submitted',
    'APPROVED' = 'approved',
    'REJECTED' = 'rejected',
    'REVISED' = 'revised'
}

export enum TimeRegistrationStatus {
    'SUBMITTED' = 'submitted',
    'APPROVED' = 'approved',
    'REJECTED' = 'rejected',
    'REVISED' = 'revised'
}

export enum Type { 'COMPANY' = 'company', 'EMPLOYEE' = 'employee', 'CONSUMER' = 'consumer' }

export enum Type { 'STANDARD' = 'standard', 'DISASSEMBLY' = 'disassembly', 'ASSEMBLY' = 'assembly' }

export enum Type { 'STANDARD' = 'standard', 'GO_AHEAD_EAGLES' = 'go_ahead_eagles' }
export type UserDto = {
    id?: number;
    first_name?: string;
    last_name?: string;
    name?: string;
    email?: string;
    phone_number?: string;
    favoriet?: boolean;
    approved?: boolean;
    permanent_staff_member?: boolean;
    driving_licenses?: { [key: number]: DrivingLicense } | null;
    color_code?: string | null;
    remember_token?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
};

export enum VatRates { 'HIGH' = 21, 'LOW' = 9, 'NONE' = 0 }
