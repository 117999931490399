<script setup>
import Button from '@/Components/Buttons/Button.vue';
import FormButton from '@/Components/Buttons/FormButton.vue';
import Card from '@/Components/Card/Card.vue';
import GaeSchema from '@/Components/GaeSchema/form.vue';
import {useUser} from '@/stores/userStore';
import {v4} from 'uuid';

const user = useUser();

const props = defineProps({
    event: Object,
    form: Object,
    whatOptions: Object,
    whoOptions: Object,
});

function addLine() {
    props.form.template.push({
        id: v4(),
        time: {
            value: '',
            type: 'time',
        },
        number: {
            value: '',
            type: 'text',
        },
        who: {
            value: '',
            type: 'select',
        },
        what: {
            value: '',
            type: 'select',
        },
    });
}
</script>
<template>
    <Card
        collapsable
        collapsed
    >
        <template #title>
            Schema
        </template>

        <GaeSchema
            :form="form"
            :what-options="whatOptions"
            :who-options="whoOptions"
        />

        <template #buttons>
            <Button
                label="Regel toevoegen"
                @click="addLine"
            />
            <FormButton
                v-if="user.canAny(['update-settings'])"
                :data="{ ...form, schema: form.schema }"
                :url="route('events.update', { event: props.event.id, stay: true })"
                label="Opslaan"
                method="put"
            />
        </template>
    </Card>
</template>
