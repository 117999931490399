<script setup>
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import {QuillEditor} from '@vueup/vue-quill';
import {computed} from 'vue';
import Label from '@/Components/Input/Label.vue';

const props = defineProps(['modelValue', 'label']);
const emit = defineEmits(['update:modelValue']);

const value = computed({
    get() {
        return !props.modelValue ? '' : props.modelValue;
    },
    set(value) {
        const input = value === '<p><br></p>' ? null : value;
        emit('update:modelValue', input);
    },
});

const options = {
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{list: 'ordered'}, {list: 'bullet'}],
            [{align: []}],
            ['link', 'image'],
            [{header: [1, 2, 3, 4, 5, 6, false]}],
        ],
    },
};
</script>

<template>
    <div class="flex min-h-50 flex-col">
        <Label :label="label" />
        <QuillEditor
            v-model:content="value"
            :options="options"
            content-type="html"
            theme="snow"
        />
    </div>
</template>
