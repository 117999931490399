<script setup>
import Button from '@/Components/Buttons/Button.vue';
import Card from '@/Components/Card/Card.vue';
import Password from '@/Components/Input/Password.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import {mapErrors} from '@/utilities';
import {Head, useForm} from '@inertiajs/vue3';
import {inject} from 'vue';

const route = inject('route');
const props = defineProps({
    email: String,
    token: String,
});

const form = useForm({
    token: props.token,
    email: props.email,
    password: '',
    password_confirmation: '',
});

const submit = () => {
    form.post(route('password.store'), {
        onFinish: () => form.reset('password', 'password_confirmation'),
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <GuestLayout>
        <Head title="Reset Password" />
        <Card narrow>
            <FormKit
                v-model="form.email"
                :errors="form.errors?.email"
                :value="form.email"
                label="Email"
                type="email"
            />

            <Password
                v-model="form.password"
                :errors="form.errors?.password"
                :label="$t('Password')"
            />

            <Password
                v-model="form.password_confirmation"
                :errors="form.errors?.password_confirmation"
                :label="$t('Confirm Password')"
            />

            <template #buttons>
                <Button
                    :form="form"
                    label="Wachtwoord resetten"
                    @click="submit"
                />
            </template>
        </Card>
    </GuestLayout>
</template>
