<script setup>
import ConformationButton from '@/Components/Buttons/ConformationButton.vue';
import MultiButton from '@/Components/Buttons/MultiButton.vue';
import Card from '@/Components/Card/Card.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import AttachmentsForm from '@/Pages/Events/Partials/AttachmentsForm.vue';
import EventForm from '@/Pages/Events/Partials/Forms/EventForm.vue';
import GAEForm from '@/Pages/Events/Partials/Forms/GaeForms.vue';
import GaeTable from '@/Pages/Events/Partials/Forms/GaeTable.vue';
import ReminderForm from '@/Pages/Events/Partials/Forms/ReminderForm.vue';
import {useUser} from '@/stores/userStore';
import {mapErrors} from '@/utilities';
import {useForm} from '@inertiajs/vue3';
import {inject} from 'vue';
import Form from "@/Components/Input/Form.vue";

const dayjs = inject('dayjs');
const route = inject('route');
const user = useUser();

const props = defineProps({
    query: Object,
    event: Object,
    attendances: Object,
    statusOptions: Object,
    typeOptions: Object,
    userOptions: Object,
    contactOptions: Object,
    quotationOptions: Object,
    whoOptions: Object,
    whatOptions: Object,
    template: Object,
    staffingLocations: Array,
    repeatUnitOptions: Object,
    ownerOptions: Object,
});

const form = useForm({
    type: Object.keys(props.typeOptions)[0],
    contact_id: '',
    quotation_id: '',
    title: '',
    description: '',
    all_day: true,
    postcode: '',
    street_name: '',
    street_number: '',
    street_number_addition: '',
    city: '',
    latitude: '',
    longitude: '',
    capacity: '',
    attachments: [],
    repeat: props.event.series_id !== null,
    ...props.event,
    start: props.event.all_day
        ? dayjs(props.event.start).format('YYYY-MM-DD')
        : dayjs(props.event.start).format('YYYY-MM-DD HH:mm'),
    end: props.event.all_day ? null : dayjs(props.event.end).format('YYYY-MM-DD HH:mm'),
    children: props.event.children.map((child) => {
        child.start = child.all_day
            ? dayjs(child.start).format('YYYY-MM-DD')
            : dayjs(child.start).format('YYYY-MM-DD HH:mm');

        child.end = child.all_day ? null : dayjs(child.end).format('YYYY-MM-DD HH:mm');

        return child;
    }),
    ...props.event.address,
    schema: props.event.schema ? props.event.schema : props.template,
    staffing: props.event.staffing ? props.event.staffing : [],
    owner_ids: props.event.owners ? props.event.owners.map((owner) => owner.id) : [],
});

const submitForm = async (button = false) => {
    form.transform((data) => {
        data.schema = data.template;
        return data;
    }).put(
        route('events.update', {
            event: props.event.id,
            invite: button === 'save and invite',
            invite_everybody: button === 'save and invite everybody',
            future: button === 'save and future',
            all: button === 'save and all',
        }),
        {
            preserveState: true,
            preserveScroll: true,
            onError: (errors) => (form.errors = mapErrors(errors)),
        }
    );
};

</script>

<template>
    <AppLayout>
        <Card collapsable>
            <template #title>
                Event aanpassen
            </template>
            <Form>
                <ReminderForm
                    v-if="form.type === 'agenda_item' || form.type === 'personal_appointment'"
                    :create="false"
                    :event="event"
                    :form="form"
                    :owner-options="ownerOptions"
                    :repeat-unit-options="repeatUnitOptions"
                    :type-options="typeOptions"
                />

                <EventForm
                    v-if="form.type === 'event'"
                    :contact-options="contactOptions"
                    :form="form"
                    :owner-options="ownerOptions"
                    :quotation-options="quotationOptions"
                    :type-options="typeOptions"
                />

                <GAEForm
                    v-if="form.type === 'go_ahead_eagles'"
                    :contact-options="contactOptions"
                    :event="event"
                    :form="form"
                    :owner-options="ownerOptions"
                    :quotation-options="quotationOptions"
                    :staffing-locations="staffingLocations"
                    :type-options="typeOptions"
                />
            </Form>

            <template
                v-if="user.canAny(['delete-events', 'update-events'])"
                #buttons
            >
                <ConformationButton
                    v-if="user.can('delete-events')"
                    :url="route('events.destroy', event.id)"
                    conformation-message="Weet je zeker dat je dit event wilt verwijderen?"
                    conformation-title="Verwijderen"
                    label="Verwijderen"
                    method="delete"
                />

                <MultiButton
                    v-if="event.series_id"
                    :form="form"
                    :items="[
                        { label: 'Deze en hier op volgende aanpassen', value: 'save and future' },
                        { label: 'Alle in de toekomst aanpassen', value: 'save and all' },
                    ]"
                    label="Alleen deze aanpassen"
                    @button-click="submitForm"
                />

                <MultiButton
                    v-else
                    :events="[
                        { label: 'Opslaan en favorieten uitnodigen', value: 'save and invite' },
                        { label: 'Opslaan en iedereen uitnodigen', value: 'save and invite everybody' },
                    ]"
                    :form="form"
                    label="Opslaan"
                    @button-click="submitForm"
                />
            </template>
        </Card>

        <GaeTable
            v-if="form.type === 'go_ahead_eagles'"
            :event="event"
            :form="form"
            :what-options="whatOptions"
            :who-options="whoOptions"
        />

        <AttachmentsForm :event="event" />
    </AppLayout>
</template>
