<script setup>
import ConformationButton from '@/Components/Buttons/ConformationButton.vue';
import Card from '@/Components/Card/Card.vue';
import Errors from '@/Components/Input/Errors.vue';
import Label from '@/Components/Input/Label.vue';
import theme from '@/formkitTheme';
import {useUser} from '@/stores/userStore';
import {mapErrors} from '@/utilities';
import {useForm} from '@inertiajs/vue3';
import {inject, ref} from 'vue';

const route = inject('route');
const user = useUser();

const props = defineProps({
    event: Object,
});

const form = useForm({
    file: null,
});

const file = ref(null);

function uploadFile() {
    form.transform((data) => {
        if (file.value.files.length === 1) {
            data.file = file.value.files[0];
        }

        return data;
    }).post(route('events.attachments.store', {event: props.event.id, stay: true}), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
}
</script>
<template>
    <Card
        collapsable
        collapsed
    >
        <template #title>
            Bijlages
        </template>

        <div :class="theme.global.outer">
            <Label label="Bestand uploaden">
                <div
                    :class="theme.global.input"
                    class="text-gray relative flex items-center pl-4"
                >
                    Klik hier om een bestand te uploaden
                    <div
                        v-if="form.processing"
                        class=""
                    >
                        <span class="absolute right-0 top-1/2 -translate-x-1/2 -translate-y-1/2 duration-300">
                            <svg
                                class="text-blue size-5 animate-spin"
                                fill="none"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    class="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    stroke-width="4"
                                />
                                <path
                                    class="opacity-75"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    fill="currentColor"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
                <input
                    ref="file"
                    class="hidden"
                    type="file"
                    @change="uploadFile"
                >
            </Label>
            <Errors :errors="form.errors.file" />
        </div>

        <ul
            v-if="event.attachments.length > 0"
            class="divide-y divide-gray-200 rounded-md border border-gray-200"
            role="list"
        >
            <a
                v-for="attachment in event.attachments"
                :key="attachment.id"
                :href="route('attachments.show', { attachment: attachment.id })"
                class="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                target="_blank"
            >
                <div class="flex w-0 flex-1 items-center">
                    <svg
                        aria-hidden="true"
                        class="size-5 shrink-0 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                    >
                        <path
                            clip-rule="evenodd"
                            d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                            fill-rule="evenodd"
                        />
                    </svg>
                    <span class="ml-2 w-0 flex-1 truncate">{{ attachment.name }}</span>
                </div>
                <div class="ml-4 flex shrink-0 gap-4">
                    <ConformationButton
                        v-if="user.can('update-events')"
                        :conformation-message="`Weet je zeker dat je ${attachment.name} wilt verwijderen?`"
                        :url="
                            route('events.attachments.destroy', {
                                event: event.id,
                                attachment: attachment.id,
                                stay: true,
                            })
                        "
                        conformation-title="Verwijderen"
                        label="Verwijderen"
                        method="delete"
                    />
                </div>
            </a>
        </ul>
    </Card>
</template>
