<script setup>
import FormButton from '@/Components/Buttons/FormButton.vue';
import InputGroup from '@/Components/Input/Group.vue';
import PageTitle from '@/Components/Page/Title.vue';
import Pagination from '@/Components/Pagination.vue';
import Table from '@/Components/Table/Table.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import {useUser} from '@/stores/userStore';

import {useForm} from '@inertiajs/vue3';
import {useDebounceFn} from '@vueuse/shared';
import {computed, inject} from 'vue';
import {toSelect} from "@/utilities.js";

const route = inject('route');
const user = useUser();

const props = defineProps({
    query: Object,
    categories: Object,
    parentOptions: Array,
});

const form = useForm({
    orderBy: props.query.orderBy ?? 'name',
    orderDir: props.query.orderDir ?? 'desc',
    search: props.query.search ?? '',
    page: props.query.page ?? 1,
    parent_id: props.query.parent_id ?? '',
});

const getData = (page = 1) => {
    form.page = page;

    form.get(route('categories.index'), {
        preserveState: true,
        only: ['categories', 'query'],
    });
};

const debouncedGetData = useDebounceFn(() => getData(), 300);

const tableSettings = [
    {label: 'Titel', orderBy: 'title', width: '15%'},
    {label: 'Order', cellType: 'order', orderBy: 'order', width: '15%'},
    {label: 'Hoofd categorie', width: '15%'},
    {label: '', width: '1%', cellType: 'arrow', permission: user.can('update-categories')},
];

const tableData = computed(() =>
    props.categories.data.map((category) => {
        return {
            id: category.id,
            rowData: [
                category.title,
                {
                    model_type: 'App\\Models\\Category',
                    model_id: category.id,
                },
                category.super_category?.title,
                route('categories.edit', category.id)
            ],
        };
    })
);
</script>

<template>
    <AppLayout>
        <PageTitle title="Categorieën">
            <FormButton
                v-if="user.can('update-categories')"
                :url="route('categories.create')"
                label="Categorie toevoegen"
                method="get"
            />
        </PageTitle>
        <Table
            v-model:orderBy="form.orderBy"
            v-model:orderDir="form.orderDir"
            :loading="form.processing"
            :table-data="tableData"
            :table-settings="tableSettings"
            @update:order-by="getData()"
            @update:order-dir="getData()"
        >
            <template #filters>
                <InputGroup>
                    <FormKit
                        v-model="form.search"
                        :errors="form.errors?.search"
                        :value="form.search"
                        label="Zoeken"
                        suffix-icon="search"
                        type="text"
                        @input="debouncedGetData"
                    />
                    <FormKit
                        v-model="form.parent_id"
                        :errors="form.errors?.parent_id"
                        :options="toSelect(parentOptions)"
                        :value="form.parent_id"
                        label="Hoofd categorie"
                        type="select"
                        @input="debouncedGetData"
                    />
                </InputGroup>
            </template>

            <Pagination
                :page-data="categories"
                @set-page="getData"
            />
        </Table>
    </AppLayout>
</template>
