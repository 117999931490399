<script setup>
import {Link} from '@inertiajs/vue3';
import {useUser} from "@/stores/userStore.js";
import {computed} from "vue";

const user = useUser();

const props = defineProps({
    navigation: Object,
});

const allowedNavigation = computed(() =>
    props.navigation
        .map((itemGroup) => {
            itemGroup = itemGroup.filter((item) => !(
                    (typeof item.permission !== 'undefined' && !user.canAny(item.permission))
                    || (typeof item.hideOnPermission !== 'undefined' && user.canAny(item.hideOnPermission))
                )
            );
            return itemGroup;
        })
        .filter((itemGroup) => itemGroup.length > 0)
);
</script>

<template>
    <nav class="mt-8 flex-1 space-y-1 bg-pine-500">
        <template
            v-for="(itemGroup, i) in allowedNavigation"
            :key="i"
        >
            <div
                v-if="i !== 0"
                class="relative py-5"
            >
                <div
                    aria-hidden="true"
                    class="absolute inset-0 flex items-center"
                >
                    <div class="w-full border-t border-pine-800" />
                </div>
            </div>

            <Link
                v-for="item in itemGroup"
                :key="item.name"
                :class="[
                    item.current ? 'bg-pine-800 text-beige-600' : 'text-white hover:bg-pine-800',
                    'group flex items-center rounded-lg px-4 py-3 text-sm',
                ]"
                :href="item.href"
                class="group"
            >
                <component
                    :is="item.icon"
                    :class="['mr-3 size-6 shrink-0 group-hover:text-beige-600']"
                    aria-hidden="true"
                />
                {{ item.name }}
            </Link>
        </template>
    </nav>
</template>
