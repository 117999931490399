<script setup>
import Button from '@/Components/Buttons/Button.vue';
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue';

defineProps({
    title: {
        type: String,
        required: false,
    },
    modelValue: {
        type: Boolean,
        required: true,
    },
})

const emits = defineEmits(['update:modelValue'])

function close() {
    emits('update:modelValue', false);
}
</script>
<template>
    <TransitionRoot
        :show="modelValue"
        as="template"
    >
        <Dialog
            as="div"
            class="relative z-[9999]"
            @close="close()"
        >
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel
                            class="relative overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                        >
                            <div
                                v-if="title"
                                class="items-top flex justify-between border-b border-pine-100 px-4 py-5 sm:rounded-t-lg sm:px-6"
                            >
                                <DialogTitle
                                    as="h3"
                                    class="text-lg font-medium leading-tight"
                                >
                                    {{ title }}
                                </DialogTitle>
                            </div>


                            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <slot />
                            </div>
                            <div class="gap-4 bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <slot name="buttons" />
                                <Button
                                    :label="$t('Cancel')"
                                    @click="close()"
                                />
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
