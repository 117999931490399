<script setup>
import FormButton from '@/Components/Buttons/FormButton.vue';
import InputGroup from '@/Components/Input/Group.vue';
import PageTitle from '@/Components/Page/Title.vue';
import Pagination from '@/Components/Pagination.vue';
import Table from '@/Components/Table/Table.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import {useUser} from '@/stores/userStore';

import {useForm} from '@inertiajs/vue3';
import {useDebounceFn} from '@vueuse/shared';

import {computed, inject} from 'vue';

import Errors from '@/Components/Input/Errors.vue';
import Label from '@/Components/Input/Label.vue';
import theme from '@/formkitTheme';

const route = inject('route');
const user = useUser();

const props = defineProps({
    query: Object,
    skills: Object,
});

const form = useForm({
    search: props.query.search ?? '',
    orderBy: props.query.orderBy ?? 'name',
    orderDir: props.query.orderDir ?? 'desc',
    page: props.query.page ?? 1,
});

const getData = (page = 1) => {
    form.page = page;

    form.get(route('skills.index'), {
        preserveState: true,
        only: ['skills', 'query'],
    });
};
const debouncedGetData = useDebounceFn(() => getData(), 300);

const tableSettings = computed(() => [
    {label: 'Naam', orderBy: 'name', width: '15%'},
    {label: 'Gebruikers', width: '15%', from: 'md'},
    {label: '', width: '1%', cellType: 'arrow', permission: user.can('update-skills')},
]);

const tableData = computed(() =>
    props.skills.data.map((skill) => {
        return {
            id: skill.id,
            rowData: [
                skill.name,
                skill.users
                    .map((user) => user.name)
                    .splice(0, 5)
                    .join(', ') + (skill.users.length > 5 ? '...' : ''),
                route('skills.edit', skill.id),
            ],
        };
    })
);
</script>

<template>
    <AppLayout>
        <PageTitle title="Vaardigheden">
            <FormButton
                v-if="user.can('create-skills')"
                :url="route('skills.create')"
                label="Vaardigheid toevoegen"
                method="get"
            />
        </PageTitle>

        <Table
            v-model:orderBy="form.orderBy"
            v-model:orderDir="form.orderDir"
            :loading="form.processing"
            :table-data="tableData"
            :table-settings="tableSettings"
            @update:order-by="getData()"
            @update:order-dir="getData()"
        >
            <template #filters>
                <InputGroup>
                    <div :class="theme.global.outer">
                        <Label label="Zoeken" />
                        <input
                            v-model="form.search"
                            :class="theme.global.input"
                            type="text"
                            @input="debouncedGetData"
                        >
                        <Errors :errors="form.errors?.search" />
                    </div>
                </InputGroup>
            </template>

            <Pagination
                :page-data="skills"
                @set-page="getData"
            />
        </Table>
    </AppLayout>
</template>
