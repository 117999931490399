<script setup>
import InputGroup from '@/Components/Input/Group.vue';
import AttachmentsInputForm from "@/Pages/Events/Partials/AttachmentsInputForm.vue";
import AttachmentsInput from "@/Pages/Events/Partials/Forms/AttachmentsInput.vue";
import {inject, onMounted} from 'vue';
import SelectMultiple from "@/Components/Input/SelectMultiple.vue";
import TimeInput from "@/Components/Input/TimeInput.vue";

const dayjs = inject('dayjs');

const props = defineProps({
    form: Object,
    event: Object,
    typeOptions: Object,
    repeatUnitOptions: Object,
    create: Boolean,
    ownerOptions: Object,
});

onMounted(() => {
    if (props.create && props.form.start === '') {
        props.form.all_day = false;
        props.form.start = dayjs().format('YYYY-MM-DD HH:mm');
        setEnd();
    }
});

function setEnd() {
    if (!props.form.all_day) {
        let day = dayjs(props.form.start);

        if (day.isValid()) {
            props.form.end = dayjs(props.form.start).add(1, 'hour').format('YYYY-MM-DD HH:mm');
        }
    }
}
</script>
<template>
    <FormKit
        v-model="form.title"
        :errors="form.errors?.title"
        :value="form.title"
        label="Titel"
        type="text"
    />
    <FormKit
        v-model="form.description"
        :errors="form.errors?.description"
        :value="form.description"
        label="Omschrijving"
        rows="4"
        type="textarea"
    />

    <SelectMultiple
        v-model="form.owner_ids"
        :errors="form.errors?.owner_ids"
        :options="ownerOptions"
        label="Eigenaren"
        placeholder="Kies een eigenaar"
    />

    <FormKit
        v-model="form.all_day"
        :errors="form.errors?.all_day"
        :value="form.all_day"
        label="Hele dag"
        type="checkbox"
    />

    <template v-if="form.all_day">
        <TimeInput
            v-model="form.start"
            :errors="form.errors?.start"
            label="Start"
        />
    </template>

    <template v-else>
        <InputGroup>
            <TimeInput
                v-model="form.start"
                :errors="form.errors?.start"
                label="Start"
            />
            <TimeInput
                v-model="form.end"
                :errors="form.errors?.end"
                label="Eind"
            />
        </InputGroup>
    </template>

    <FormKit
        v-if="create"
        v-model="form.repeat"
        :errors="form.errors?.repeat"
        :value="form.repeat"
        label="Herhalen"
        type="checkbox"
    />
    <InputGroup v-if="form.repeat && create">
        <FormKit
            v-model="form.series_interval"
            :errors="form.errors?.series_interval"
            :value="form.series_interval"
            label="elke"
            type="number"
        />
        <FormKit
            v-model="form.series_interval_unit"
            :errors="form.errors?.series_interval_unit"
            :options="repeatUnitOptions"
            :value="form.series_interval_unit"
            label="Herhalen"
            type="select"
        />
        <FormKit
            v-model="form.series_end"
            :errors="form.errors?.series_end"
            :value="form.series_end"
            label="Tot"
            type="date"
        />
    </InputGroup>

    <AttachmentsInput
        v-if="create"
        :form="form"
    />
    <AttachmentsInputForm
        v-if="!create"
        :event="event"
    />
</template>
