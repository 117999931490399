<script lang="ts" setup>
import {inject} from 'vue';
import Card from '@/Components/Card/Card.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import {useForm} from "@inertiajs/vue3";
import {mapErrors} from "@/utilities.js";
import MultiButton from "@/Components/Buttons/MultiButton.vue";
import {useUser} from "@/stores/userStore";
import {QuotationDto} from "@t/generated";
import {defaultText} from "@/Pages/Quotations/Partials/defaultText";
import RichTextInput from "@/Components/RichTextInput.vue";
import Group from "@/Components/Input/Group.vue";
import Form from "@/Components/Input/Form.vue";

const user = useUser();
const route = inject('route') as typeof import('ziggy-js').default;

const props = defineProps<{
    query: object;
    quotation: QuotationDto;
    templateOptions: object;
}>();


const form = useForm({
    template: Object.keys(props.templateOptions)[0],
    show_total: true,
    ...props.quotation,
    intro: props.quotation.intro ?? defaultText.intro,
    outro: props.quotation.outro ?? defaultText.outro,
    email: props.quotation.email ?? defaultText.email,
});

const submitForm = async (button: string) => {
    form.put(
        route('quotations.update', {quotation: props.quotation.id, stay: true}),
        {
            preserveState: true,
            preserveScroll: true,
            onError: (errors) => (form.errors = mapErrors(errors)),
            onSuccess: () => {
                if (button === 'save and preview') {
                    window.open(route('quotations.preview', {id: props.quotation.id}), '_blank');
                }
            },
        }
    );
    return true;
};

</script>

<template>
    <AppLayout>
        <Card>
            <template #title>
                Offerte opmaak
            </template>
            <Form>
                <Group>
                    <FormKit
                        v-model="form.template"
                        :errors="form.errors?.template"
                        :options="templateOptions"
                        :value="form.template"
                        label="Offerte template"
                        type="select"
                    />
                    <FormKit
                        v-model="form.show_total"
                        :errors="form.errors?.show_total"
                        :options="[
                            { label: 'Ja', value: true },
                            { label: 'Nee', value: false },
                        ]"
                        :value="form.show_total"
                        label="Toon totaal"
                        type="select"
                    />
                </Group>

                <RichTextInput
                    v-model="form.intro"
                    :errors="form.errors?.intro"
                    :value="form.intro"
                    label="Introductie"
                />

                <template v-for="(line,i) in form.quotation_lines">
                    <RichTextInput
                        v-if="line.product"
                        v-model="line.appendix_outro"
                        :errors="form.errors?.quotation_lines"
                        :label="`Bijlage subtekst ${line.product.title}`"
                    />
                </template>


                <RichTextInput
                    v-model="form.outro"
                    :errors="form.errors?.outro"
                    :value="form.outro"
                    label="Afsluiting"
                />
            </Form>

            <template #buttons>
                <MultiButton
                    v-if="user.can('update-quotations')"
                    :form="form"
                    :items="[
                        { label: 'Opslaan en bekijken', value: 'save and preview' },
                    ]"
                    label="Opslaan"
                    @button-click="submitForm"
                />
            </template>
        </Card>
    </AppLayout>
</template>
