<script setup>
import Button from '@/Components/Buttons/Button.vue';
import ConformationButton from '@/Components/Buttons/ConformationButton.vue';
import Card from '@/Components/Card/Card.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import Form from '@/Pages/TimeRegistration/Partials/Form.vue';
import {useUser} from '@/stores/userStore';
import {mapErrors} from '@/utilities';
import {useForm} from '@inertiajs/vue3';
import {inject} from 'vue';

const route = inject('route');
const user = useUser();

const props = defineProps({
    query: Object,
    timeRegistration: Object,
    userOptions: Object,
    eventOptions: Object,
    statusOptions: Object,
});

const form = useForm({
    date: '',
    start_time: '',
    end_time: '',
    break: '00:00',
    // duration_assembly: null,
    // duration_disassembly: null,
    // drinks: '0',
    description: '',
    user_id: '',
    event_id: '',
    ...props.timeRegistration,
});

const submitForm = () => {
    form.put(route('time-registrations.update', props.timeRegistration.id), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <AppLayout>
        <div class="">
            <Card>
                <template #title>
                    Uren aanpassen
                </template>

                <Form
                    :event-options="eventOptions"
                    :form="form"
                    :status-options="statusOptions"
                    :user-options="userOptions"
                />

                <template #buttons>
                    <ConformationButton
                        v-if="user.canAny(['delete-time-registrations', 'delete-own-time-registrations'])"
                        :url="route('time-registrations.destroy', timeRegistration.id)"
                        conformation-message="Weet je zeker dat je deze tijdregistratie wilt verwijderen?"
                        conformation-title="Verwijderen"
                        label="Verwijderen"
                        method="delete"
                    />
                    <Button
                        v-if="user.canAny(['create-time-registrations', 'create-own-time-registrations'])"
                        :form="form"
                        label="Opslaan"
                        @click="submitForm"
                    />
                </template>
            </Card>
        </div>
    </AppLayout>
</template>
