<script setup>
import Button from '@/Components/Buttons/Button.vue';
import FormButton from '@/Components/Buttons/FormButton.vue';
import SmallButton from '@/Components/Buttons/SmallButton.vue';
import Card from '@/Components/Card/Card.vue';
import GenericInput from '@/Components/Input/GenericInput.vue';
import Label from '@/Components/Input/Label.vue';
import RoleModal from '@/Components/Modal/RoleModal.vue';
import {useUser} from '@/stores/userStore';

import {PlusIcon, TrashIcon} from '@heroicons/vue/24/outline';
import {v4} from 'uuid';
import {computed, ref} from 'vue';

const user = useUser();

const props = defineProps({
    form: Object,
    event: Object,
    staffingLocations: Array,
});
const locationNameInput = ref('');

if (props.form.staffing.length === 0) {
    props.staffingLocations.forEach((loc) => addLocation(loc));
}

const acceptedAttendants = computed(() => {
    return props.event.attendances.filter((a) => a.status === 'accepted').map((a) => a.user.name);
});

function addLocation(loc = null) {
    props.form.staffing.push({
        id: v4(),
        name: loc ?? locationNameInput.value,
        roles: [
            {
                name: 'Bar',
                employees: [],
            },
            {
                name: 'Ober',
                employees: [],
            },
            {
                name: 'Uithaler',
                employees: [],
            },
        ],
    });

    locationNameInput.value = '';
}

const showModal = ref(false);
const location = ref({});
const role = ref({});

function openModal(loc, ro) {

    location.value = loc;
    role.value = ro;
    showModal.value = true;
}
</script>
<template>
    <Card
        collapsable
        collapsed
    >
        <template #title>
            Bezetting
        </template>

        <template
            v-for="location in form.staffing"
            :key="location.id"
        >
            <div class="text flex items-center justify-between py-4 font-bold">
                <div class="">
                    {{ location.name }}
                </div>
                <SmallButton
                    red
                    @click="removeEmployee(employee.id)"
                >
                    <TrashIcon
                        aria-hidden="true"
                        class="size-5 cursor-pointer text-white"
                    />
                </SmallButton>
            </div>
            <div class="mb-4 grid gap-4 md:grid-cols-3">
                <template
                    v-for="role in location.roles"
                    :key="role.name"
                >
                    <button
                        class="flex min-h-12 flex-col rounded border hover:bg-grey-50"
                        @click="openModal(location, role)"
                    >
                        <span class="sfont-bold py-2 text-sm"> {{ role.name }}</span>

                        <ul class="grid border-t px-2 py-1 text-left 2xl:grid-cols-2">
                            <li
                                v-for="employee in role.employees"
                                :key="employee.id"
                                class="py-1"
                            >
                                {{ employee.name }}
                            </li>
                            <span v-if="role.employees.length == 0"> Geen </span>
                        </ul>
                    </button>
                </template>
            </div>
            <!-- <Divider ignore-padding /> -->
        </template>
        <div class="flex gap-4">
            <GenericInput
                v-model="locationNameInput"
                class="col-span-4"
                label="Locatie toevoegen"
                type="text"
            />
            <div>
                <Label label="&nbsp;" />
                <SmallButton @click="addLocation()">
                    <PlusIcon
                        aria-hidden="true"
                        class="size-5 text-white"
                    />
                </SmallButton>
            </div>
        </div>
        <template #buttons>
            <FormButton
                v-if="user.canAny(['update-settings'])"
                :data="{ ...form, schema: form.template }"
                :url="route('events.update', { event: props.event.id, stay: true })"
                label="Opslaan"
                method="put"
            />
        </template>
    </Card>
    <RoleModal
        v-model="showModal"
        v-model:staffing="form.staffing"
        :accepted-attendants="acceptedAttendants"
        :location="location"
        :role="role"
    />
</template>
