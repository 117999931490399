<script setup>
import {useForm} from '@inertiajs/vue3';
import {inject} from 'vue';

import Button from '@/Components/Buttons/Button.vue';
import Card from '@/Components/Card/Card.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import {useUser} from '@/stores/userStore';
import {mapErrors} from '@/utilities';
import Form from '@/Pages/Categories/Partials/Form.vue';

const route = inject('route');
const user = useUser();

const props = defineProps({
    categoryOptions: Object,
    roleOptions: Object,
});

const form = useForm({
    title: '',
    quotation_description: '',
    category_id: null,
    role_ids: [],
    ...props.query,
});

const submitForm = () => {
    form.transform((data) => ({
        ...data,
        category_id: parseInt(data.category_id),
    })).post(route('categories.store'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <AppLayout>
        <div class="sm:px-6 md:px-8">
            <Card>
                <template #title>
                    Categorie aanmaken
                </template>

                <div class="grid grid-cols-1 gap-2">
                    <Form
                        :category-options="categoryOptions"
                        :form="form"
                        :role-options="roleOptions"
                    />
                </div>

                <template #buttons>
                    <Button
                        v-if="user.can('create-categories')"
                        :form="form"
                        label="Opslaan"
                        @click="submitForm"
                    />
                </template>
            </Card>
        </div>
    </AppLayout>
</template>
